import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';
import { ProductsMenu } from '../entities/productMenu';
import { HttpService } from './http-service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService extends HttpService {

  constructor(public http: Http, public toastService: ToastrService) {
    super(http, toastService);
  }

  getMenu(): Observable<ProductsMenu[]> {
    return new Observable((o) => this.get('/principal/menu', (resp) => o.next(resp), (error) => o.error(error)));
  }
}
