import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { startWith } from 'rxjs/operators';
import { Configuracoes } from 'src/app/entities/configuracoes';
import { Order } from 'src/app/entities/order';
import { OrderItem } from 'src/app/entities/orderItem';
import { ObserverPedido, PedidoService } from "src/app/services/pedido-service";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, OnDestroy, ObserverPedido {
  @Output() showCart = new EventEmitter<void>();
  @Output() hideCart = new EventEmitter<void>();
  titleBackgroundColor: string;
  items: OrderItem[] = [];
  orderItems: OrderItem[] = [];
  imageApi: string;
  total = 0;
  SITE;

  constructor(
    private pedidoService: PedidoService,
    private router: Router
  ) {
    this.titleBackgroundColor = Configuracoes.SITE.backgroundColorSliderSuperior;
  }

  notify(pedido: Order) {
    this.orderItems = pedido.itens;
    this.refresh();
  }

  ngOnInit() {
    this.SITE = Configuracoes.SITE;
    this.imageApi = Configuracoes.API_IMAGENS;
    this.orderItems = this.pedidoService.pedido.itens;
    this.refresh();
    this.pedidoService.registerObserver(this);
  }

  ngOnDestroy(): void {
    this.pedidoService.removeObserver(this);
  }

  removeItem(item: OrderItem) {
    this.pedidoService.removeCartItem(item.produto, (order: Order) => {
      this.pedidoService.select(order);
      this.pedidoService.removed();
      if (!this.items.length)
        this.hideCart.emit();
    });
  }

  refresh() {
    this.items = [];
    let productsMap = new Map();

    this.orderItems
      .sort((previous, item) => {
        if (previous.id < item.id) {
          return 1;
        } else if (previous.id > item.id) {
          return -1;
        } else {
          return 0;
        }
      })
      .forEach(item => {
        if (!productsMap.get(item.produto)) {
          productsMap.set(item.produto, { ...item });
        } else {
          productsMap.get(item.produto).quantidade += item.quantidade;
          productsMap.get(item.produto).total += item.total;
        }
      });

    productsMap.forEach((item) => {
      if (item.quantidade > 0)
        this.items.push(item)
    });

    this.total = this.items
      .map(item => item.total)
      .reduce((previous, current) => previous + current, 0);
  }

  redirectTo(url: string) {
    this.hideCart.emit();
    this.router.navigate([url]);
  }

  public changeItemQuantity(value: number) {
    console.log(value);
  }
}
