import { Injectable } from "@angular/core";
import { HttpService } from "./http-service";
import { Router } from "@angular/router";
import { Http, Headers, RequestOptions } from "@angular/http";
import { ToastrService } from "ngx-toastr";
import { Configuracoes } from "../entities/configuracoes";

@Injectable({
  providedIn: "root",
})
export class LogService extends HttpService {
  private url = "https://logs.agilb2b.com.br/";
  //private url = 'http://localhost:8001/';

  constructor(
    public router: Router,
    public http: Http,
    public toastService: ToastrService
  ) {
    super(http, toastService);
  }

  userLogin(login) {
    let msg = {
      id: login.id,
      login: login.login,
      nome: login.nome,
      tipo: login.tipo,
    };
    this.http
      .post(
        this.url + "user-login",
        msg,
        this.getHttpOptionsLog(Configuracoes.API_BRANCH)
      )
      .subscribe();
  }

  orderCreated(id) {
    let msg = { id: id };
    this.http
      .post(
        this.url + "order-created",
        msg,
        this.getHttpOptionsLog(Configuracoes.API_BRANCH)
      )
      .subscribe();
  }

  orderFinished(id) {
    let msg = { id: id };
    this.http
      .post(
        this.url + "order-finished",
        msg,
        this.getHttpOptionsLog(Configuracoes.API_BRANCH)
      )
      .subscribe();
  }

  getHttpOptionsLog(apiBranch) {
    var header = new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiBranch,
    });
    return new RequestOptions({
      headers: header,
    });
  }
}
