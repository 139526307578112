import { Component, ViewEncapsulation, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "simple-html",
  template: `
    <div
      [style.white-space]="whiteSpace ? 'pre-wrap' : 'none'"
      [innerHTML]="_html"
    ></div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class SimpleHtmlComponent {
  _html: SafeHtml = "";

  @Input()
  whiteSpace: Boolean = true; //pre-wrap

  @Input()
  set html(str: any) {
    if (str) {
      this._html = this._sanitizer.bypassSecurityTrustHtml(str);
    }
  }
  get html() {
    return this._html;
  }

  constructor(private _sanitizer: DomSanitizer) {}
}
