import { Component } from "@angular/core";
import { Configuracoes } from "src/app/entities/configuracoes";
import { ConfiguracoesMisc } from "src/app/entities/configuracoesMisc";
import { ConfiguracoesSite } from "src/app/entities/configuracoesSite";

@Component({
  selector: "gcc-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
  SITE: ConfiguracoesSite;
  MISC: ConfiguracoesMisc;

  public midiasSociais;
  public institucionalTitle1;
  public institucionalTitle2;
  public ondeEstamos;
  public API_IMAGENS: String;

  public values = [];
  /*public values:Array<InfoPageValue> = [
    {pageId: 'quem-somos', label: 'Quem Somos', group:1, html: '<h2>Quem Somos</h2>'},
    {pageId: 'como-comprar', label: 'Como Comprar', group:2, html: '<h2>Como Comprar</h2>'},
  ];*/

  constructor() {
    this.SITE = Configuracoes.SITE;
    this.MISC = Configuracoes.MISC;
    this.API_IMAGENS = Configuracoes.API_IMAGENS;
    this.institucionalTitle1 = Configuracoes.SITE.institucionalTitle1;
    this.institucionalTitle2 = Configuracoes.SITE.institucionalTitle2;
    this.values = Configuracoes.SITE.infoPage;
    this.ondeEstamos = Configuracoes.SITE.ondeEstamos;
    this.midiasSociais = Configuracoes.SITE.midiasSociais;
  }
}
