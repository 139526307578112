import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpService } from "./http-service";
import { Http } from "@angular/http";
import { ToastrService } from "ngx-toastr";
import { InitProvider } from "./init-provider";

@Injectable({
  providedIn: "root",
})
export class SecurityService extends HttpService {
  public form;
  public callbackLoginOk;
  public callbackError;

  constructor(
    public router: Router,
    public http: Http,
    public toastService: ToastrService,
    public initProvider: InitProvider
  ) {
    super(http, toastService);
  }

  login(formLogin, callbackSuccess, callbackError) {
    this.form = formLogin;
    this.callbackLoginOk = callbackSuccess;
    this.callbackError = callbackError;
    this.post(
      "auth",
      formLogin,
      this.loginSuccess.bind(this),
      this.loginError.bind(this)
    );
  }

  recuperaSenha(form, callbackSuccess, callbackError) {
    this.post("auth/recuperaSenha", form, callbackSuccess, callbackError);
  }

  loginSuccess(resp) {
    this.initProvider.usuario = resp;
    localStorage.setItem("token", resp.token);
    localStorage.setItem("tipoUsuario", resp.tipo);
    this.toastService.info("Usuário logado com sucesso!");
    this.router.navigate([""]);
    this.callbackLoginOk(resp);
    //console.log('login usuario', resp);
  }

  loginError(error) {
    this.callbackError(error);
  }

  isType(type: string) {
    return (
      localStorage.getItem("tipoUsuario") != null &&
      localStorage.getItem("tipoUsuario") == type
    );
  }

  hasToken() {
    return window.localStorage.getItem("token") != null;
  }

  logout() {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("tipoUsuario");
    window.localStorage.removeItem("pedido");
    window.location.href = "/";
    this.initProvider.usuario = null;
  }
}
