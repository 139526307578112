import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UsuarioService } from "src/app/services/usuario-service";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import Swal from "sweetalert2";
import { InitProvider } from "src/app/services/init-provider";
import { Configuracoes } from "src/app/entities/configuracoes";
declare var $: any;

type Boleto = {
  lancamento: number;
  cliente: number;
  numeroDocumento: string;
  nossoNumero: string;
  dataVencimento: string;
  valorBoleto: number;
  sacadoNome: string;
  sacadoCnpj: string;
  sacadoCep: string;
  sacadoBairro: string;
  sacadoLogradouro: string;
  sacadoCidade: string;
  sacadoEstado: string;
  codBanco: string;
  conta: string;
  agencia: string;
  carteira: string;
  dataEmissao: string;
  cedenteNome: string;
  cedenteCnpj: string;
  instrucao1: string;
  instrucao2: string;
  instrucao3: string;
  ativo: boolean;
};

@Component({
  selector: "app-page-meus-dados",
  templateUrl: "./page-meus-dados.component.html",
  styleUrls: ["./page-meus-dados.component.css"],
})
export class PageMeusDadosComponent implements OnInit {
  public list = 1;
  public formPassword: FormGroup;
  public documentLabel = "CPF";
  public documentMask = "000.000.000-00";
  public errorPassword: Boolean = false;
  public errorMsgPassword: String;
  public boletos = [];
  public boletosCopy = [];
  public empty = false;
  public boletoSelected;
  public arrSacado = [];

  constructor(
    private route: ActivatedRoute,
    private usuarioService: UsuarioService,
    public initProvider: InitProvider,
    private formBuilder: FormBuilder
  ) {
    this.list = 1;
    this.route.queryParams.subscribe((params) => {
      console.log(params);

      if (params["list"]) this.list = params["list"];
    });
    this.formPassword = this.formBuilder.group({
      senhaAntiga: ["", Validators.required],
      senhaNova: ["", Validators.required],
      senhaNovaConfirm: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.usuarioService.getBoletos(
      function (resp) {
        this.boletos = resp;
        this.boletosCopy = resp;
        let values = [];
        for (let row in resp) {
          values.push(resp[row]["sacadoNome"]);
        }
        this.arrSacado = [...new Set(values)];
        if (this.boletos.length < 1) this.empty = true;
      }.bind(this)
    );
  }

  mudaSacado(e) {
    let filter = e.target.value;
    if (filter) {
      this.boletos = [
        ...this.boletosCopy.filter((b) => b.sacadoNome == filter),
      ];
    } else {
      this.boletos = [...this.boletosCopy];
    }
  }

  printBoletoPdf(boleto: Boleto) {
    const headers = new Headers({
      Branch: Configuracoes.API_BRANCH,
      Authorization: "Bearer " + window.localStorage.getItem("token"),
    });
    fetch(
      `${Configuracoes.API}impressoes/boleto?pedido=${boleto.numeroDocumento}&lancamento=${boleto.lancamento}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  onSubmitPassword() {
    if (!this.formPassword.valid) {
      this.errorPassword = true;
      this.errorMsgPassword = "Confira o formulário corretamente!";
      return;
    }

    if (
      this.formPassword.value.senhaNova !=
      this.formPassword.value.senhaNovaConfirm
    ) {
      this.errorPassword = true;
      this.errorMsgPassword = "Nova senha e a confirmação não conferem!";
      return;
    }

    if (this.formPassword.valid && !this.usuarioService.loading)
      this.usuarioService.updatePassword(
        this.formPassword.value.senhaAntiga,
        this.formPassword.value.senhaNova,
        function (resp) {
          this.errorPassword = false;
          this.formPassword.reset();
          Swal.fire("Senha com sucesso!", "", "success");
        }.bind(this),
        function (error) {
          //console.log(error);
          this.errorPassword = true;
          this.errorMsgPassword = error._body;
        }.bind(this)
      );
  }
}
