import { Injectable } from "@angular/core";
import { Http, RequestOptions, Headers } from "@angular/http";
import { map } from "rxjs/operators";
import { Configuracoes } from "../entities/configuracoes";
import { Usuario } from "../entities/usuario";
declare var $: any;

type Banner = {
  id: number;
  link: string;
  image: string;
  imageMobile: string;
  ordem: number;
};

@Injectable({
  providedIn: "root",
})
export class InitProvider {
  public usuario: Usuario;
  public filiais: any = [];
  public banners: Banner[] | undefined;

  constructor(public http: Http) {}

  async load() {
    return await new Promise((resolve) => {
      this.http
        .get("appconfig-v2.json", this.getHttpOptions())
        .pipe(map((config) => config.json()))
        .subscribe((config) => {
          Configuracoes.patchAppConfig(config);
          resolve(
            new Promise((resolve) => {
              this.http
                .get(Configuracoes.API + "principal", this.getHttpOptions())
                .pipe(map((principal) => principal.json()))
                .subscribe(async (principal) => {
                 if(Configuracoes.API_BANNERS !== null)
                    {
                      await new Promise((resolve) => {
                        this.http
                          .get(Configuracoes.API_BANNERS, this.getHttpOptions())
                          .pipe(map((resp) => resp.json() as Banner[]))
                          .subscribe(async (banners) => {
                            principal.configuracao.sliderPrincipal = banners;
                            resolve(true);
                          });
                      });
                    }
                  console.log("principal", principal.configuracao);

                  Configuracoes.SITE = principal.configuracao;
                  this.filiais = principal.filiais.filter((f) => f.ativo);
                  if (this.hasToken()) {
                    resolve(
                      new Promise((resolve) => {
                        this.http
                          .get(
                            Configuracoes.API + "usuario",
                            this.getHttpOptionsLogin()
                          )
                          .pipe(map((resp) => resp.json()))
                          .subscribe(
                            (resp) => {
                              this.usuario = resp;
                              if (Configuracoes.SITE.appendHeadAfterLogin) {
                                $("head").append(
                                  Configuracoes.SITE.appendHeadAfterLogin
                                );
                              }
                              resolve(true);
                            },
                            (error) => {
                              window.localStorage.removeItem("token");
                              window.localStorage.removeItem("tipoUsuario");
                              window.localStorage.removeItem("pedido");
                              window.location.href = "/login";
                            }
                          );
                      })
                    );
                  } else {
                    resolve(true);
                  }
                });
            })
          );
        });
    });
  }

  hasToken() {
    return window.localStorage.getItem("token") != null;
  }

  getToken() {
    return window.localStorage.getItem("token");
  }

  getHttpOptionsLogin() {
    var header = new Headers({
      "Content-Type": "application/json",
      Branch: Configuracoes.API_BRANCH,
    });
    if (this.hasToken()) {
      header.set("Authorization", "Bearer " + this.getToken());
    }
    return new RequestOptions({
      headers: header,
    });
  }

  getHttpOptions() {
    var header = new Headers({
      "Content-Type": "application/json",
      Branch: Configuracoes.API_BRANCH,
    });
    return new RequestOptions({
      headers: header,
    });
  }
}
