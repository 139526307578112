import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Configuracoes } from 'src/app/entities/configuracoes';
import { ConfiguracoesSite } from 'src/app/entities/configuracoesSite';
import { CartService } from 'src/app/services/cart-service';
import { PedidoService } from 'src/app/services/pedido-service';
import { ProductService } from 'src/app/services/product-service';
import { SecurityService } from 'src/app/services/security-service';
import { getOrderItemFromProduct } from '../shared/function';

@Component({
  selector: 'app-catalog-item',
  templateUrl: './catalog-item.component.html',
  styleUrls: ['./catalog-item.component.css']
})
export class CatalogItemComponent implements OnInit {

  @Input()
  public product: any;
  @Input()
  public produtoColXL;
  @Input()
  public produtoHeight;
  @Input()
  public produtoDescrHeight;
  @Input()
  public produtoDescrFontSize;
  @Input()
  public produtoIndispFontSize;
  @Input()
  public produtoDescrLen;

  public showStock: boolean;
  public pedido: any;
  public API_IMAGENS: String;
  public SITE: ConfiguracoesSite;
  public mostraPreco = false;
  public stock: string;

  constructor(
    public productService: ProductService,
    public securityService: SecurityService,
    public pedidoService: PedidoService,
    public cartService: CartService
  ) {
    this.API_IMAGENS = Configuracoes.API_IMAGENS;
    this.SITE = Configuracoes.SITE;
    this.pedido = this.pedidoService.pedido;
    if (!this.securityService.hasToken() && !this.SITE.mostraPrecoLoginAtivo)
      this.mostraPreco = true;
    if (this.securityService.hasToken() && (!this.SITE.mostraPrecoPedidoSelecionado || this.pedido)) {
      this.mostraPreco = true;
    }
  }

  ngOnInit() {
    if (this.SITE.quantidadeMaximaGrade == false) {
      this.stock = `${this.product.estoque}`;
    } else if (this.product.estoque > this.SITE.quantidadeMaximaGrade) {
      this.stock = `+${this.SITE.quantidadeMaximaGrade}`;
    } else {
      this.stock = `${this.product.estoque}`;
    }

    if (this.pedido) {
      this.showStock = this.pedido.tipo.validaEstoque;
    } else {
      this.showStock = this.SITE.mostraEstoqueDigitacao
    }
  }

  public addCart(quantity: number) {
    if (this.product.sku) {
      const tax = this.product.imposto;
      const price = this.product.precopromocional ? this.product.precopromocional : this.product.preco;

      let taxValue = null;
      let taxCalc = {};

      if (tax) {
        taxCalc = this.productService.calculaImposto(price, tax.ipi, tax.alicms, tax.substicms, tax.alicmsorigem, tax.fcpSt);
        taxValue = this.productService.calculaValorImposto(price, tax.ipi, tax.alicms, tax.substicms, tax.alicmsorigem, tax.fcpSt);
      }

      const item = getOrderItemFromProduct(this.product, taxCalc, tax, taxValue, this.pedido.clientes[0].cliente.id, quantity, quantity * this.getPrice());
      this.pedidoService.setCartItemsSku([item], (res) => this.doOnSuccess(res, [item]), console.log);
    }
  }

  public doOnSuccess(res, items) {
    this.cartService.addItems(items);
    this.pedidoService.select(res);
  }

  public getPrice() {
    let taxValue = 0;
    const tax = this.product.imposto;

    if (tax) {
      const price = this.product.precopromocional ? this.product.precopromocional : this.product.preco;
      taxValue = this.productService.calculaValorImposto(price, tax.ipi, tax.alicms, tax.substicms, tax.alicmsorigem, tax.fcpSt);
    }

    return this.product.preco + taxValue;
  }
}
