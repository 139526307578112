import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead/public_api";
import { PedidoService } from "src/app/services/pedido-service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { InitProvider } from "src/app/services/init-provider";
import { Configuracoes } from "src/app/entities/configuracoes";
import { ConfiguracoesSite } from "src/app/entities/configuracoesSite";
import { OrderClient } from "src/app/entities/orderClient";
import { Observable } from "rxjs";
import { UsuarioService } from "src/app/services/usuario-service";
import { BsLocaleService, DateFormatter } from "ngx-bootstrap/datepicker";
import { LogService } from "src/app/services/log-service";
import { Order } from "src/app/entities/order";
import { Location } from "@angular/common";
declare var $: any;

@Component({
  selector: "app-page-novo-pedido",
  templateUrl: "./page-novo-pedido.component.html",
  styleUrls: ["./page-novo-pedido.component.css"],
})
export class PageNovoPedidoComponent implements OnInit {
  SITE: ConfiguracoesSite;

  public error: Boolean = false;
  public errorMsg: String;
  public form: FormGroup;

  typingCliente: string;
  noResult = false;
  selectedCliente: any;
  listCliente: any = [];
  tabelas: any = [];
  tabelasUsuario: any = [];
  tabelasCliente: any = [];
  tabelasFilial: any = [];
  pedidoTiposUsuario: any = [];

  clientesUsuario: Observable<any[]>;

  filiais: any = [];
  errorClienteTabela = false;
  errorClienteBloqueado = false;

  private format = new DateFormatter();
  dataEntregaMinDate: Date;
  dataEntregaDaysDisable = [];
  clienteIdErp = {};
  pedidoOriginal;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private initProvider: InitProvider,
    private usuarioService: UsuarioService,
    private localeService: BsLocaleService,
    private pedidoService: PedidoService,
    private logService: LogService,
    private activeRoute: ActivatedRoute,
    private location: Location
  ) {
    this.SITE = Configuracoes.SITE;
    this.dataEntregaMinDate = new Date();

    if (
      this.dataEntregaMinDate.getHours() > this.SITE.dataEntregaHorarioLimite
    ) {
      this.dataEntregaMinDate.setDate(
        this.dataEntregaMinDate.getDate() +
          1 +
          this.SITE.dataEntregaCarenciaPrazo
      );
    } else {
      this.dataEntregaMinDate.setDate(
        this.dataEntregaMinDate.getDate() + this.SITE.dataEntregaCarenciaPrazo
      );
    }
    this.dataEntregaDaysDisable = JSON.parse(
      "[" + this.SITE.dataEntregaDiasDesabilitados + "]"
    );
    this.localeService.use("pt-br");
    this.clientesUsuario = Observable.create((observer: any) => {
      if (!this.usuarioService.loading) {
        this.usuarioService.getClientes(
          this.typingCliente,
          function (resp) {
            observer.next(resp);
          }.bind(this)
        );
      }
    });
    this.filiais = initProvider.filiais;
    this.form = this.formBuilder.group({
      tabela: ["", Validators.required],
      pedidoTipo: ["", Validators.required],
    });
    if (this.SITE.mostraDataEntrega) {
      this.form.addControl(
        "dataEntrega",
        new FormControl("", [Validators.required])
      );
    }
    if (this.filiais.length == 1) {
      this.form.addControl(
        "filial",
        new FormControl(this.filiais[0].id, [Validators.required])
      );
      this.selecionaFilial(this.filiais[0].id);
    } else {
      this.form.addControl(
        "filial",
        new FormControl("", [Validators.required])
      );
    }
    this.usuarioService.getTabelas(
      function (resp) {
        this.tabelasUsuario = resp;
        this.usuarioService.getPedidoTipos(
          function (resp) {
            console.log(resp);
            this.pedidoTiposUsuario = resp;
            if (this.pedidoTiposUsuario.length == 1) {
              this.form.controls.pedidoTipo.setValue(
                this.pedidoTiposUsuario[0].id
              );
            }
          }.bind(this)
        );
      }.bind(this)
    );

    this.pedidoOriginal = this.router.getCurrentNavigation().extras.state;

    if (this.pedidoOriginal) {
      for (let cliente_ in this.pedidoOriginal.clientes) {
        this.selecionaCliente(this.pedidoOriginal.clientes[cliente_].cliente);
        $("#cliente").val("");
      }

      this.form.controls["tabela"].setValue(this.pedidoOriginal.tabela.id);
      this.form.controls["pedidoTipo"].setValue(this.pedidoOriginal.tipo.id);

      if (
        this.pedidoOriginal.dataEntrega &&
        this.pedidoOriginal.dataEntrega != null
      )
        this.form.controls["dataEntrega"].setValue(
          this.pedidoOriginal.dataEntrega
        );
    }
  }

  onSubmit() {
    if (!this.listCliente.length) {
      this.error = true;
      this.errorMsg = "Selecione um ou mais clientes!";
      return;
    }
    console.log(this.clienteIdErp);

    if (!this.form.valid) {
      this.error = true;
      this.errorMsg = "Confira o formulário corretamente!";
      return;
    }
    if (this.form.valid && !this.pedidoService.loading) {
      let filialId = this.form.get("filial").value;
      let tabelaId = this.form.get("tabela").value;
      let pedidoTipoId = this.form.get("pedidoTipo").value;
      let pedidoClientes: OrderClient[] = [];
      let dataEntrega = "";
      if (this.SITE.mostraDataEntrega) {
        dataEntrega = this.format.format(
          this.form.get("dataEntrega").value,
          "DD/MM/YYYY",
          "en"
        );
      }
      this.listCliente.forEach((c) => {
        pedidoClientes.push({
          idEnderecoErp: this.clienteIdErp[c.id],
          cliente: c.id,
          quantidade: 0,
          subtotal: 0,
          impostos: 0,
          acrescimo: 0,
          acrescimoPercent: 0,
          desconto: 0,
          descontoPercent: 0,
          total: 0,
        });
      });
      let formPedido = {
        clientes: pedidoClientes,
        filial: filialId,
        tabela: tabelaId,
        tipo: pedidoTipoId,
        dataEntrega: dataEntrega,
      };
      this.pedidoService.abrir(
        formPedido,
        function (resp) {
          this.pedidoService.select(resp);
          Swal.fire("Pedido", "Aberto com sucesso!", "success");

          if (
            this.pedido !== undefined &&
            Configuracoes.modoVisualizacaoCatalogo
          )
            window.localStorage.setItem(
              "visualizacaoModo",
              Configuracoes.modoVisualizacaoCatalogo
            );

          this.router.navigate(["/catalogo"]);
          this.logService.orderCreated(resp.id);
        }.bind(this),
        function (error) {
          let obj = JSON.parse(error._body);
          if (obj && obj.msg) {
            this.error = true;
            this.errorMsg = obj.msg;
          }
        }.bind(this)
      );
    }
  }

  onSelect(event: TypeaheadMatch): void {
    this.selecionaCliente(event.item);
  }

  selecionaCliente(cliente: any) {
    this.errorClienteTabela = false;
    this.errorClienteBloqueado = false;
    //console.log(event.item);

    if (cliente.bloqueiaPedidos) {
      this.errorClienteBloqueado = true;
      this.setSelectedCliente(cliente);
      $("#cliente").val("");
      return;
    }

    this.clienteIdErp[cliente.id] = cliente.idEnderecoErp
      ? cliente.idEnderecoErp + ""
      : "";

    if (this.listCliente.length > 0) {
      let comp1 = "";
      let comp2 = "";
      cliente.tabelas.forEach((t) => {
        comp1 += t.id;
      });
      this.listCliente[this.listCliente.length - 1].tabelas.forEach((t) => {
        comp2 += t.id;
      });
      if (comp1 != comp2) {
        this.errorClienteTabela = true;
        this.setSelectedCliente(cliente);
        $("#cliente").val("");
        return;
      }
    }

    console.log(cliente);

    this.tabelasCliente = cliente.tabelas.filter((f) => f.ativo);
    this.setSelectedCliente(cliente);
    this.addCliente(cliente);
    this.openTabelas();
    $("#cliente").val("");
  }

  setSelectedCliente(c: any) {
    this.selectedCliente = c;
  }

  excluiCliente(c: any) {
    for (var i = 0; i < this.listCliente.length; i++) {
      if (this.listCliente[i].id == c.id) {
        this.listCliente.splice(i, 1);
        break;
      }
    }
    if (this.selectedCliente && this.selectedCliente.id == c.id) {
      if (this.listCliente.length) {
        this.selectedCliente = this.listCliente[this.listCliente.length - 1];
      } else {
        this.tabelasCliente = [];
        this.selectedCliente = null;
        this.openTabelas();
      }
    }
  }

  addCliente(c: any) {
    for (var i = 0; i < this.listCliente.length; i++) {
      if (this.listCliente[i].id == c.id) return;
    }
    this.listCliente.push(c);
  }

  selecionaFilial(idFilial) {
    let filial: any;
    for (var i = 0; i < this.filiais.length; i++) {
      if (this.filiais[i].id == idFilial) filial = this.filiais[i];
    }
    if (!filial) return;

    this.tabelasFilial = filial.tabelas.filter((f) => f.ativo);
    this.openTabelas();
  }

  openTabelas() {
    this.form.controls.tabela.setValue("");
    if (this.tabelasFilial.length) {
      this.tabelas = this.tabelasFilial;
    } else if (this.tabelasCliente.length) {
      this.tabelas = this.tabelasCliente;
    } else {
      this.tabelas = this.tabelasUsuario;
    }
    if (this.tabelas.length > 0) {
      this.form.controls.tabela.setValue(this.tabelas[0].id);
    }
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  ngOnInit() {
    if (!this.pedidoService.confirmaAbandonarPedidoEmAberto()) {
      this.location.back();
    }
  }
}
