import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SimpleHtmlComponent } from "./simple-html.component";

@NgModule({
  imports: [CommonModule],
  exports: [SimpleHtmlComponent],
  declarations: [SimpleHtmlComponent],
})
export class SimpleHtmlModule {}
