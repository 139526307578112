import { EventEmitter, Component, Input, Output, ViewChildren, QueryList } from '@angular/core';
import { ProductsMenu } from 'src/app/entities/productMenu';

@Component({
  selector: 'app-catalog-menu',
  templateUrl: './catalog-menu.component.html',
  styleUrls: ['./catalog-menu.component.css']
})
export class CatalogMenuComponent {

  @ViewChildren(CatalogMenuComponent)
  public submenu: QueryList<CatalogMenuComponent>
  @Input()
  public item: ProductsMenu;
  @Output()
  public onSelect = new EventEmitter<any[]>();
  @Input()
  public selected: boolean = false;

  constructor() { }

  public onChildrenSelected(data: any[]) {
    data.forEach((d) => {
      const child = this.item.children.find(item => item.id == d.id);
      if (child) child.selected = d.include;
    });

    const hasChildrenUnselected = this.item.children.filter(item => !item.selected).length > 0;

    this.selected = !hasChildrenUnselected;

    data.push({ include: this.selected, id: this.item.id })

    this.onSelect.emit(data);
  }

  public select(item: ProductsMenu): void {
    this.selected = !this.selected;

    const ids = [];
    ids.push({ include: this.selected, id: item.id });
    this.getSubMenu(ids);

    this.onSelect.emit(ids);
  }

  private getSubMenu(ids: any[]) {
    if (this.submenu.length)
      this.submenu.forEach(subItem => {
        subItem.selected = this.selected;
        ids.push({ include: this.selected, id: subItem.item.id });
        subItem.getSubMenu(ids);
      });
  }
}
