import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from 'src/app/app.routes';
import { SimpleHtmlModule } from 'src/app/components/simple-html/simple-html.module';
import { DirectiveModule } from 'src/app/directive/directives.module';
import { MaterialMarketingModule } from 'src/app/pages/material-marketing/material-marketing.module';
import { CatalogRoutingModule } from './catalog-routing.module';
import { CatalogDynamicMenuComponent } from './components/catalog-dynamic-menu/catalog-dynamic-menu.component';
import { CatalogItemComponent } from './components/catalog-item/catalog-item.component';
import { CatalogMenuComponent } from './components/catalog-menu/catalog-menu.component';
import { PageCatalogComponent } from './pages/page-catalog/page-catalog.component';
import { ProductQuantityInputComponent } from './components/product-quantity-input/product-quantity-input.component';
import { ProductImageComponent } from './components/product-image/product-image.component';



@NgModule({
  declarations: [
    PageCatalogComponent,
    CatalogItemComponent,
    CatalogMenuComponent,
    CatalogDynamicMenuComponent,
    ProductQuantityInputComponent,
    ProductImageComponent
  ],
  imports: [
    CommonModule,
    CatalogRoutingModule,
    MaterialMarketingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpModule,
    FormsModule,
    SimpleHtmlModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SweetAlert2Module.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
    DirectiveModule
  ],
  exports: [
    ProductQuantityInputComponent
  ]
})
export class CatalogModule { }
