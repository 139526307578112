import { Component } from "@angular/core";

@Component({
  selector: "app-page-novo-cliente",
  templateUrl: "./page-novo-cliente.component.html",
  styleUrls: ["./page-novo-cliente.component.css"],
})
export class PageNovoClienteComponent {
  constructor() {}
}
