import { Http, Headers, RequestOptions } from "@angular/http";
import { finalize, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Configuracoes } from "../entities/configuracoes";

export class HttpService {
  public loading: Boolean = false;
  public toastLoadingId: number;

  public http: Http;
  public toastService: ToastrService;

  constructor(http: Http, toastService: ToastrService) {
    this.http = http;
    this.toastService = toastService;
  }

  /*request(path, form:FormData, callback, callbackErro?, messageError?) {
        this.showLoading();

        const req = new HttpRequest('POST', Configuracoes.API + path, form, {
            reportProgress: true,
            responseType: 'text'
        });


        this.http.request(req)
                 .pipe(map( resp => resp.json()))
                 .subscribe(resp => this.callbackSuccess(resp, callback),
                            erro => this.callbackErro(erro, callbackErro, messageError));
    }*/

  postFile(path, form, callback, callbackErro?, messageError?) {
    this.showLoading();
    this.http
      .post(Configuracoes.API + path, form, this.getHttpOptionsEmpty())
      .pipe(map((resp) => resp.json()))
      .subscribe(
        (resp) => this.callbackSuccess(resp, callback),
        (erro) => this.callbackErro(erro, callbackErro, messageError)
      );
  }

  post(path, form, callback, callbackErro?, messageError?) {
    this.showLoading();
    console.log(Configuracoes.API + path);
    console.log(form);
    console.log(this.getHttpOptions());
    this.http
      .post(Configuracoes.API + path, form, this.getHttpOptions())
      .pipe(map((resp) => resp.json()))
      .subscribe(
        (resp) => this.callbackSuccess(resp, callback),
        (erro) => this.callbackErro(erro, callbackErro, messageError)
      );
  }

  get(path, callback, callbackErro?, messageError?) {
    this.showLoading();
    this.http
      .get(Configuracoes.API + path, this.getHttpOptions())
      .pipe(map((resp) => resp.json()))
      .subscribe(
        (resp) => this.callbackSuccess(resp, callback),
        (erro) => this.callbackErro(erro, callbackErro, messageError)
      );
  }

  getPromisse(path) {
    this.showLoading();
    return this.http
      .get(Configuracoes.API + path, this.getHttpOptions())
      .pipe(map((resp) => resp.json()))
      .pipe(finalize(() => this.dimissLoading()))
      .toPromise();
  }

  delete(path, callback, callbackErro?, messageError?) {
    this.showLoading();
    this.http
      .delete(Configuracoes.API + path, this.getHttpOptions())
      .pipe(map((resp) => resp.json()))
      .subscribe(
        (resp) => this.callbackSuccess(resp, callback),
        (erro) => this.callbackErro(erro, callbackErro, messageError)
      );
  }

  put(path, form, callback, callbackErro?, messageError?) {
    this.showLoading();
    this.http
      .put(Configuracoes.API + path, form, this.getHttpOptions())
      .subscribe(
        (resp) => this.callbackSuccess(resp, callback),
        (erro) => this.callbackErro(erro, callbackErro, messageError)
      );
  }

  callbackSuccess(resp, callback) {
    this.dimissLoading();
    callback(resp);
  }

  callbackErro(error, callback?, message?) {
    this.dimissLoading();

    if (error.status == 403) {
      //403 Forbidden
      window.location.href = "/login";
    }

    if (callback) {
      callback(error);
      return;
    }

    let msg = "Ocorreu um problema";
    if (message) msg = message;

    this.toastService.info(msg, "", {
      timeOut: 4000,
    });
  }

  getHttpOptions() {
    var header = new Headers({
      "Content-Type": "application/json",
      Branch: Configuracoes.API_BRANCH,
    });
    if (this.hasToken()) {
      header.set("Authorization", "Bearer " + this.getToken());
    }
    return new RequestOptions({
      headers: header,
    });
  }

  getHttpOptionsEmpty() {
    var header = new Headers({
      Branch: Configuracoes.API_BRANCH,
    });
    if (this.hasToken()) {
      header.set("Authorization", "Bearer " + this.getToken());
    }
    return new RequestOptions({
      headers: header,
    });
  }

  showLoading() {
    this.loading = true;
    this.toastLoadingId = this.toastService.show(
      '<div class="spinner-grow text-primary" role="status"><span class="sr-only">Loading...</span></div>',
      "",
      {
        enableHtml: true,
      }
    ).toastId;
  }

  dimissLoading() {
    this.loading = false;
    this.toastService.clear(this.toastLoadingId);
  }

  /*isLogged() {
        return this.getToken() != null;
    }*/

  getToken() {
    return window.localStorage.getItem("token");
  }

  hasToken() {
    return window.localStorage.getItem("token") != null;
  }
}
