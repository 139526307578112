import { Component, OnInit } from "@angular/core";
import { MaterialMarketing } from "../model/material-marketing";
import { MaterialMarketingGrupo } from "../model/material-marketing-grupo";
import { MaterialMarketingService } from "../services/material-marketing.service";

@Component({
  selector: "app-page-material-marketing",
  templateUrl: "./page-material-marketing.component.html",
  styleUrls: ["./page-material-marketing.component.css"],
})
export class PageMaterialMarketingComponent implements OnInit {
  public grupoAtual: MaterialMarketingGrupo;
  public menuAtivo: boolean;
  public grupos: MaterialMarketingGrupo[];
  public itens: MaterialMarketing[];

  constructor(private service: MaterialMarketingService) {
    this.menuAtivo = false;
  }

  ngOnInit(): void {
    this.service.listarGrupos(
      (grupos) => {
        this.grupos = grupos;
        this.menuAtivo = true;
        if (this.grupos.length > 0) this.buscaMateriais(this.grupos[0]);
      },
      (error) => {
        this.menuAtivo = true;
      }
    );
  }

  public buscaMateriais(grupo: MaterialMarketingGrupo) {
    if (this.grupoAtual != null && this.grupoAtual.id == grupo.id) return;

    this.menuAtivo = false;
    this.service.buscaMaterialMarketinPorGrupo(
      grupo.id,
      (itens) => {
        this.grupoAtual = grupo;
        this.itens = itens;
        this.menuAtivo = true;
      },
      (error) => {
        this.menuAtivo = true;
      }
    );
  }
}
