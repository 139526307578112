import { Injectable } from "@angular/core";
import { HttpService } from "./http-service";
import { Router } from "@angular/router";
import { Http, Headers, RequestOptions } from "@angular/http";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { Order } from "../entities/order";
import { Configuracoes } from "../entities/configuracoes";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";

@Injectable({
  providedIn: "root",
})
export class FreteService extends HttpService {
  constructor(
    public router: Router,
    public http: Http,
    public toastService: ToastrService
  ) {
    super(http, toastService);
  }

  getFretesAPIAgilb2b(
    pedido: Order,
    criterioVisualizacao,
    cepDestino,
    valor,
    errorFrete,
    callback
  ) {
    let peso = 0;
    let medidas = "";
    pedido.itens.forEach((i) => {
      peso += i.produtoPeso;
      medidas +=
        "[" +
        (i.produtoLargura ? i.produtoLargura : 0) +
        "," +
        (i.produtoAltura ? i.produtoAltura : 0) +
        "," +
        (i.produtoComprimento ? i.produtoComprimento : 0) +
        "],";
    });
    medidas = medidas.substring(0, medidas.length - 1);
    this.showLoading();
    let url =
      Configuracoes.API_FRETE +
      "/freteapi" +
      "?cepDestino=" +
      cepDestino +
      "&peso=" +
      peso +
      "&itens=[" +
      medidas +
      "]&valor=" +
      valor +
      "&id=" +
      pedido.id +
      (criterioVisualizacao !== null
        ? "&canal=" +
          criterioVisualizacao.canalFrete +
          "&tipoFrete=" +
          criterioVisualizacao.tipoFiltro
        : "") +
      "&error=" +
      (errorFrete ? "true" : "false");
    console.log(url);
    console.log(pedido.itens);
    this.http
      .post(
        url,
        pedido.itens,
        this.getHttpOptionsFrete(Configuracoes.APIKEY_FRETE)
      )
      .pipe(map((resp) => resp.json()))
      .subscribe(
        (resp) => {
          let receber = resp.filter(({ id }) => id !== 205);
          let retirar = resp.filter(({ id }) => id === 205)[0];

          console.log({ receber, retirar });
          callback({ receber, retirar });
          this.dimissLoading();
        },
        (error) => {
          console.log(error);
          this.dimissLoading();
        }
      );
  }

  getHttpOptionsFrete(freteApiKey) {
    var header = new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + freteApiKey,
    });
    return new RequestOptions({
      headers: header,
    });
  }
}
