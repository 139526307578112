import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Configuracoes } from "src/app/entities/configuracoes";
import { ConfiguracoesSite } from "src/app/entities/configuracoesSite";																	   
import { Order } from "src/app/entities/order";
import { Product } from "src/app/entities/product";
import { PedidoService } from "src/app/services/pedido-service";

import { SecurityService } from "src/app/services/security-service";
declare var $: any;

@Component({
  selector: "slider-produto",
  templateUrl: "./slider-produto.component.html",
  styleUrls: ["./slider-produto.component.css"],
})
export class SliderProdutoComponent {
  SITE: ConfiguracoesSite;
  _values: Product[];
  public API_IMAGENS: String;
  public pedido: Order;
  public tabelaPadrao;
  public mostraPreco = false;

  @Input() interval: Number = 3000;
  @Input() title: String;
  @Input() dots: Boolean = false;
  @Input() infinite: Boolean = true;
  @Input() autoplay: Boolean = true;

  @Input()
  set values(arr: Product[]) {
    if (arr) {
      this._values = arr;
      this.refreshSlider();
    }
  }

  get values(): Product[] {
    return this._values;
  }

  constructor(
    public pedidoService: PedidoService,
    public securityService: SecurityService,
    private router: Router						  
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;																  
    this.API_IMAGENS = Configuracoes.API_IMAGENS;
    this.pedido = this.pedidoService.pedido;
    this.SITE = Configuracoes.SITE;
    if (!this.securityService.hasToken() && !this.SITE.mostraPrecoLoginAtivo)
      this.mostraPreco = true;
    if (
      this.securityService.hasToken() &&
      (!this.SITE.mostraPrecoPedidoSelecionado || this.pedido)
    )
      this.mostraPreco = true;
    this.tabelaPadrao = this.SITE.tabelaPadrao;
  }

  refreshSlider() {
    let interval = this.interval;
    let dots = this.dots;
    let infinite = this.infinite;
    let autoplay = this.autoplay;
    $(function () {
      $(".gcc-slider-produto").each(function () {
        if ($(this).hasClass("slick-slider")) {
          return;
        }
        $(this).slick({
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: infinite,
          dots: dots,
          autoplay: autoplay,
          autoplaySpeed: interval,
          arrows: true,
          pauseOnHover: false,
          pauseOnFocus: false,
          responsive: [
            {
              breakpoint: 1140,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ],
        });
      });
    });
  }
}
