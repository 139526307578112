import { Injectable } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";

imports: [HttpClientModule];

@Injectable({
  providedIn: "root",
})
export class IpService {
  constructor(private http: HttpClient) {}
  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }
}
