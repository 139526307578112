import { NgModule } from '@angular/core';
import { VarDirective } from 'src/app/directive/ng-var.directive';
import { ClickOutsideDirective } from './ng-click-outside-directive';
import { MoveOutsideDirective } from './ng-move-outside-directive';



@NgModule({
    declarations: [
        VarDirective,
        ClickOutsideDirective,
        MoveOutsideDirective
    ],
    imports: [],
    exports: [
        VarDirective,
        ClickOutsideDirective,
        MoveOutsideDirective
    ],
})
export class DirectiveModule { }
