import { ConfiguracoesMisc } from "./configuracoesMisc";
import { ConfiguracoesSite } from "./configuracoesSite";

export class Configuracoes {
  public static NAME;
  public static API;
  public static API2;
  public static IMAGELOGO;
  public static API_BANNERS;
  public static IMAGEBACKGROUND;
  public static API_FRETE;
  public static API_IMAGENS;
  public static API_BRANCH;
  public static APIKEY_FRETE;
  public static layout;
  public static gtagId;
  public static SITE: ConfiguracoesSite;
  public static MISC: ConfiguracoesMisc = {};
  public static modoVisualizacaoCatalogo;
  public static modoVisualizacaoPageProduto: number;
  public static descontoPorItem: number;
  public static apiKeyGatewayPgto: string;
  public static mostraPrecoMinimoVenda: boolean;
  public static mostraCarrinho: boolean;
  public static exibeVisualizacaoCatalogo: number[];

  //https://themestr.app/theme
  //http://colormind.io/bootstrap/
  public static themes: Array<any> = [
    { id: "blush", color: "#b94b70" },
    { id: "chambray", color: "#396387" },
    { id: "matterhorn", color: "#4C3C42" },
    { id: "tobaccobrown", color: "#735641" },
    { id: "stilleto", color: "#97353A" },
    { id: "bazaar", color: "#917A81" },
    { id: "scarlate", color: "#8b0d13" },
    { id: "william", color: "#3C6167" },
    { id: "bastille", color: "#292530" },
  ];

  public static patchAppConfig(c) {
    this.NAME = c.NAME;
    this.API = c.API;
    this.API_BANNERS = c.API_BANNERS ? c.API_BANNERS : null;
    this.API2 = c.API2;
    this.IMAGELOGO = c.IMAGELOGO;
    this.IMAGEBACKGROUND = c.IMAGEBACKGROUND;
    this.API_IMAGENS = c.API_IMAGENS;
    this.API_BRANCH = c.API_BRANCH;
    this.layout = c.Layout;
    this.modoVisualizacaoCatalogo = c.modoVisualizacaoCatalogo
      ? c.modoVisualizacaoCatalogo
      : "4x4";
    this.modoVisualizacaoPageProduto = c.modoVisualizacaoPageProduto
      ? c.modoVisualizacaoPageProduto
      : 1;
    this.descontoPorItem = c.descontoPorItem ? c.descontoPorItem : 0;
    this.apiKeyGatewayPgto = c.APIKEY_GATEWAYPGTO;

    if (c.APIKEY_FRETE) {
      this.APIKEY_FRETE = c.APIKEY_FRETE;
    }
    if (c.API_FRETE) {
      this.API_FRETE = c.API_FRETE;
    } else {
      this.API_FRETE = "https://" + c.NAME + ".agilb2b.com.br";
    }
    if (c.gtagId) {
      this.gtagId = c.gtagId;
    }
    if (c.miscCadCliNoAutEndereco) {
      this.MISC.miscCadCliNoAutEndereco = c.miscCadCliNoAutEndereco;
    }
    if (c.registroLogin) {
      this.MISC.registroLogin = c.registroLogin;
    }
    if (c.menuDinamico) {
      this.MISC.menuDinamico = c.menuDinamico;
    }
    if (c.footer) {
      this.MISC.fotterProps = c.footer;
    }
    if (c.mostraPrecoMinimoVenda) {
      this.mostraPrecoMinimoVenda = c.mostraPrecoMinimoVenda;
    }
    if (c.mostraCarrinho !== null) {
      this.mostraCarrinho = c.mostraCarrinho;
    }
    if (c.exibeVisualizacaoCatalogo) {
      this.exibeVisualizacaoCatalogo = c.exibeVisualizacaoCatalogo;
    }
  }
}
