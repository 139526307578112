export class CreditCard {
  public pedido: number;
  public nome: string;
  public numero: string;
  public numero1: string;
  public numero2: string;
  public numero3: string;
  public numero4: string;
  public validadeMes: number;
  public validadeAno: number;
  public codigoSeguranca: number;
}
export class Payment {
  public merchantOrderId: number;
  public nomeNoCartao: string;
  public numeroCartao: string;
  public dataExpiracao: string;
  public cvv: number;
  public type: string = "CreditCard";
  public Amount: string = "amount";
  public Installments: string = "installemts";
  public parcelas: number;
  public get bandeira(): string {
    if (!this.numeroCartao) return null;

    if (this.numeroCartao.length == 0) return null;

    switch (this.numeroCartao.substring(0, 1)) {
      case "5":
        return "Mastercard";

      case "4":
        return "Visa";
    }

    return null;
  }

  public ipAdress: string;
}

export class PaymentReturn {
  public sucess?: boolean;
  public status: string;

  public static setSucess(paymentReturn: PaymentReturn) {
    if (!paymentReturn.status || paymentReturn.status == null) return;

    switch (paymentReturn.status.toLowerCase()) {
      case "operations successful":
      case "operation successful":
        paymentReturn.sucess = true;
        return;

      default:
        paymentReturn.sucess = false;
        return;
    }
  }
}

/*
"Payment": {
                 merchantOrderId,
        		"Type": "CreditCard",
        		"Amount": amount,
        		"Installments": installemts,
                "CardNumber": cardNumber,
        		"Holder": holder - nome impresso no cartão,
        		"ExpirationDate": expirationDate,
        		"SecurityCode": securityCode,
        		*"Brand": bandeira
*/
