import { EventEmitter, Component, OnInit, Output, ViewChildren, QueryList } from '@angular/core';
import { ProductsMenu } from 'src/app/entities/productMenu';
import { CatalogService } from 'src/app/services/catalog.service';
import { CatalogMenuComponent } from '../catalog-menu/catalog-menu.component';

@Component({
  selector: 'app-catalog-dynamic-menu',
  templateUrl: './catalog-dynamic-menu.component.html',
  styleUrls: ['./catalog-dynamic-menu.component.css']
})
export class CatalogDynamicMenuComponent implements OnInit {

  @ViewChildren(CatalogMenuComponent)
  public submenu: QueryList<CatalogMenuComponent>
  @Output()
  public sendFilters = new EventEmitter<number[]>();
  public filters: Set<number> = new Set();
  public rootFilters: Set<number> = new Set();
  public menu: ProductsMenu[];
  public loading: boolean = true;

  constructor(
    private catalogService: CatalogService
  ) { }

  ngOnInit() {
    this.catalogService
      .getMenu()
      .subscribe((menu) => this.menu = menu);
  }

  public selectRoot(id: number) {
    this.filters = new Set();
    this.submenu.forEach(c => this.clear(c));
    this.sendFilters.emit([id]);
  }

  private clear(item: CatalogMenuComponent) {
    item.selected = false
    item.submenu.forEach(i => this.clear(i));
  }

  public onSelect(data) {
    data.forEach(item => {
      if (item.include) {
        this.filters.add(item.id);
      } else {
        this.filters.delete(item.id);
      }
    });
    console.log(this.filters.values());

    this.sendFilters.emit(Array.from(this.filters));
  }

}
