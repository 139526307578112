import { CreditCard } from "./../../entities/creditCard";
import { Component } from "@angular/core";
import { PedidoService } from "src/app/services/pedido-service";
import { Order } from "src/app/entities/order";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { Configuracoes } from "src/app/entities/configuracoes";
import { ProductService } from "src/app/services/product-service";
import { FreteService } from "src/app/services/frete-service";
import { ConfiguracoesSite } from "src/app/entities/configuracoesSite";
import { UsuarioService } from "src/app/services/usuario-service";
import { ToastrService } from "ngx-toastr";
import { InitProvider } from "src/app/services/init-provider";
import { CurrencyPipe } from "@angular/common";
import { LogService } from "src/app/services/log-service";
import { OrderItem } from "src/app/entities/orderItem";
declare var $: any;

type Frete = {
  id?: number;
  transportadora?: number;
  label?: string;
  labelPrazo?: string;
  prazo?: number;
  valor?: number;
  oferta?: number;
  token?: string;
};

type FreteApiType = { receber: Frete[]; retirar: Frete };
@Component({
  selector: "app-page-checkout",
  templateUrl: "./page-checkout.component.html",
  styleUrls: ["./page-checkout.component.css"],
})
export class PageCheckoutComponent {
  SITE: ConfiguracoesSite;
  public modo = 1;
  public error: Boolean = false;
  public firstOpen = false;
  public errorMsg: String;
  public worningMessage: String;
  public form: FormGroup;
  public API_IMAGENS: String;
  public formaPagamento: any = [];
  public pedidoClientes = [];
  public pedidoItens = [];
  public pedidoItensQuantSum = 0;
  public pedidoItensPrecoSum = 0;
  public pedidoClienteSelected;
  public pedido: Order;
  public mapProdutos;
  public mapClientes;
  public countPCDescFilial: number = 0;
  public countPCAcresFilial: number = 0;
  public subTotal: number = 0;
  public valorTotal: number = 0;
  public acrescimoTotal: number = 0;
  public descontoTotal: number = 0;
  public impostosTotal: number = 0;
  public impostos = [];
  public itens = [];
  public checkErrorEstoqueBool = false;
  public fretes: FreteApiType = { receber: [], retirar: {} };
  public files = [];
  public tipoFrete;
  public freteOferta;
  public freteToken;
  public uploadFile;
  public freteShow = false;
  public freteSalvoDescr;
  pedidoTiposUsuario: any = [];
  filiais: any = [];
  public pedidoFilial;
  private currencyPipe: CurrencyPipe = new CurrencyPipe("pt-BR");
  public fatalError = false;
  public selectedCliente;
  public clienteIdErp;
  public showFreteSum = false;
  public errorFrete = false;
  public freteCepDestino = "";
  public currentFormaPagamento;
  public idDelete;
  public idDeleteModo2;
  cifPermited = false;
  fobPermited = false;

  toRoute(modo) {
    this.modo = modo;
    window.localStorage.setItem("checkoutModo", modo);
    this.atualizaPedidoItens();
  }

  onSelectedFile(e) {
    console.log(e);

    if (!e.target.files[0]) {
      Swal.fire("Arquivo", "Informe um arquivo corretamente.", "warning");
      return;
    }
    let file = e.target.files[0];

    //Upload de arquivos pdfs, requerido, concatenação utilizada
    if (this.uploadFile == 1) {
      let ext = file.name.split(".")[1].toLowerCase();
      console.log(file);
      if ("pdf" === ext) {
        console.log("push");

        this.files.push(file);
        return;
      }
      Swal.fire(
        "Arquivo",
        "Este arquivo selecionado não é do tipo PDF.",
        "warning"
      );
    }
  }

  onRemoveFile(index) {
    this.files.splice(index, 1);
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public pedidoService: PedidoService,
    private usuarioService: UsuarioService,
    private freteService: FreteService,
    private productService: ProductService,
    private initProvider: InitProvider,
    private toastService: ToastrService,
    private logService: LogService
  ) {
    this.SITE = Configuracoes.SITE;
    this.API_IMAGENS = Configuracoes.API_IMAGENS;

    if (!this.pedidoService.pedido) return;

    this.filiais = initProvider.filiais;

    let checkoutModo = parseInt(window.localStorage.getItem("checkoutModo"));
    if (checkoutModo) {
      this.modo = checkoutModo;
    }

    this.notify(this.pedidoService.pedido);
  }

  getCep(idEnderecoErp) {
    let cep = "";
    if (this.selectedCliente.idEnderecoErp == idEnderecoErp) {
      cep = this.selectedCliente.cep;
    } else {
      for (let i = 0; i < this.selectedCliente.enderecos.length; i++) {
        if (this.selectedCliente.enderecos[i].idEnderecoErp == idEnderecoErp) {
          cep = this.selectedCliente.enderecos[i].cep;
        }
      }
    }
    return cep;
  }

  selecionaCliente(c: any) {
    this.selectedCliente = c;
  }

  savePedidoFilial() {
    if (this.pedidoService.loading) {
      return;
    }
    this.pedidoService.editFilial(
      this.pedido.filial.id,
      function (resp) {
        this.pedidoService.select(resp);
        this.notify(resp);
        this.toastService.success(
          this.pedido.filial.codigo + "- " + this.pedido.filial.descricao,
          this.SITE.labelFilial,
          { positionClass: "toast-bottom-left" }
        );
      }.bind(this)
    );
  }

  savePedidoTipo() {
    if (this.pedidoService.loading) {
      return;
    }
    this.pedidoService.editTipo(
      this.pedido.tipo.id,
      function (resp) {
        this.pedidoService.select(resp);
        this.notify(resp);
        this.toastService.success(
          this.pedido.tipo.tipo + " - " + this.pedido.tipo.descricao,
          this.SITE.labelPedidoTipo,
          { positionClass: "toast-bottom-left" }
        );
      }.bind(this)
    );
  }

  calculaFrete(cepDestino) {
    let element = document.getElementById("btn-retirar");
    element.style.backgroundColor = "";
    let element2 = document.getElementById("calc");
    element2.style.backgroundColor = "#1c5c46";

    document.getElementById("receber").style.display = "";
    document.getElementById("divfrete").style.display = "";
    document.getElementById("retirar").style.display = "none";
    //document.getElementById('aviso').style.display = 'none';
    if (this.freteService.loading) return;

    if (cepDestino.length != 8) {
      Swal.fire("CEP", "Informe um número de CEP corretamente.", "warning");
      return;
    }

    if (!Configuracoes.APIKEY_FRETE) {
      Swal.fire(
        "Frete",
        "Chave da API ÁgilB2B não disponível, por favor entre em contato com o administrador.",
        "warning"
      );
      return;
    }

    let errorMsgFrete = "";
    let mapProdFrete = {};
    this.pedido.itens.forEach((i) => {
      if (
        !i.produtoPeso ||
        !i.produtoLargura ||
        !i.produtoAltura ||
        !i.produtoComprimento
      ) {
        mapProdFrete[i.produtoReferencia] = true;
      }
    });

    this.form.controls.freteId.setValue("");
    this.form.controls.freteValor.setValue("");
    this.form.controls.freteDescr.setValue("");
    this.form.controls.freteCepDestino.setValue(cepDestino);
    this.fretes = { receber: [], retirar: {} };
    this.atualizaValores();

    if (errorMsgFrete) {
      Swal.fire({
        title: "Frete não poderá ser calculado",
        html: errorMsgFrete,
        icon: "warning",
        confirmButtonText: "Confirma",
      });
      this.errorFrete = true;
      //return;
    }

    let criterioVisualizacao = null;
    if (
      this.currentFormaPagamento.cvvs !== null &&
      this.currentFormaPagamento.cvvs.length
    )
      criterioVisualizacao = this.currentFormaPagamento.cvvs.find((cv) => {
        return (
          (this.form.controls.freteModalidade.value == 1 &&
            cv.tipoFrete == "FOB") ||
          (this.form.controls.freteModalidade.value == 0 &&
            cv.tipoFrete == "CIF")
        );
      });
    this.freteService.getFretesAPIAgilb2b(
      this.pedido,
      criterioVisualizacao,
      cepDestino,
      this.valorTotal,
      this.errorFrete,
      function (resp) {
        this.fretes = resp;
      }.bind(this)
    );
  }

  notify(pedido: Order) {
    this.firstOpen = true;
    this.error = false;
    this.formaPagamento = [];
    this.pedidoClientes = [];
    this.pedidoItens = [];
    this.pedidoItensQuantSum = 0;
    this.pedidoItensPrecoSum = 0;
    this.countPCDescFilial = 0;
    this.countPCAcresFilial = 0;
    this.subTotal = 0;
    this.valorTotal = 0;
    this.acrescimoTotal = 0;
    this.descontoTotal = 0;
    this.impostosTotal = 0;
    this.impostos = [];
    this.itens = [];
    this.checkErrorEstoqueBool = false;
    this.fretes = { receber: [], retirar: {} };
    this.files = [];
    this.freteShow = false;
    this.pedidoTiposUsuario = [];
    //this.showEditTipo = false;

    /////////
    //this.showEditFilial = false;
    this.fatalError = false;
    this.freteSalvoDescr = "";
    this.showFreteSum = false;
    this.errorFrete = false;
    this.tipoFrete = this.pedidoService.pedido.tipo.tipoFrete;
    this.uploadFile = this.pedidoService.pedido.tipo.uploadFile;

    //caso tenha mais que 01 cliente não será possível calcular frete
    if (this.tipoFrete > 0) {
      if (pedido.clientes.length > 1) {
        Swal.fire(
          "Pedido",
          "Mais de um cliente para realizar o frete. Por favor altere o tipo de pedido.",
          "info"
        );
        this.fatalError = true;
      }
    }

    //formulário de frete, carregando caso pedido esteja salvo
    let freteId = pedido["frete"] ? pedido["frete"] : "";
    let freteValor = pedido["freteValor"] ? pedido["freteValor"] + "" : "0";
    this.freteCepDestino = pedido["freteCepDestino"]
      ? pedido["freteCepDestino"]
      : "";
    let freteDescr = pedido["freteDescr"] ? pedido["freteDescr"] : "";
    let freteNome = pedido["freteNome"] ? pedido["freteNome"] : "";
    let freteSobrenome = pedido["freteSobrenome"]
      ? pedido["freteSobrenome"]
      : "";
    let freteModalidade = "";
    if (pedido["freteModalidade"] === 0 || pedido["freteModalidade"]) {
      freteModalidade = pedido["freteModalidade"] + "";
    }
    this.freteSalvoDescr = freteDescr;

    //verificando se banco usa endereço padrão na entidade cliente e array em cliente.enderecos
    if (pedido.clientes[0].cliente.endereco) {
      this.clienteIdErp = {};
      this.selectedCliente = pedido.clientes[0].cliente;
      for (let i = 0; i < pedido.clientes.length; i++) {
        this.clienteIdErp[pedido.clientes[i].cliente.id] =
          pedido.clientes[i].idEnderecoErp + "";
      }
      if (!pedido["freteCepDestino"]) {
        this.freteCepDestino = this.getCep(pedido.clientes[0].idEnderecoErp);
      }
    }

    //verificando o bloqueio do formulário
    let disabled = false;
    if (pedido.salvoCheckout) {
      disabled = true;
    }

    //iniciando formulário simples
    let formObj: any = {
      formaPagamento: [
        { value: "", disabled: disabled },
        [Validators.required],
      ],
      observacao: [pedido.observacao],
      observacaoNota: [pedido.observacaoNota],
    };

    //iniciando os tipos de frete, manual ou API
    if (this.tipoFrete == 1) {
      if (freteValor != "") {
        freteValor.replace(".", ",");
      }
      formObj["tipoFrete"] = ["1", [Validators.required]];
      formObj["freteDescr"] = ["Valor manual", [Validators.required]];
      formObj["freteValor"] = [
        { value: freteValor, disabled: disabled },
        [Validators.required],
      ];
      formObj["freteCepDestino"] = [
        {
          value: this.freteCepDestino,
          disabled: !pedido.tipo.permiteDigitacaoCep,
        },
        [Validators.required],
      ];
      if (pedido.tipo.modalidadeFreteCF) {
        formObj["freteModalidade"] = [
          { value: freteModalidade, disabled: disabled },
          [Validators.required],
        ];
      }
    } else if (this.tipoFrete == 2) {
      formObj["tipoFrete"] = ["2", [Validators.required]];
      formObj["freteId"] = [
        { value: freteId, disabled: disabled },
        [Validators.required],
      ];
      formObj["freteValor"] = [
        { value: freteValor, disabled: disabled },
        [Validators.required],
      ];
      formObj["freteCepDestino"] = [
        {
          value: this.freteCepDestino,
          disabled: !pedido.tipo.permiteDigitacaoCep,
        },
        [Validators.required],
      ];
      formObj["freteDescr"] = [
        { value: freteDescr, disabled: disabled },
        [Validators.required],
      ];
      formObj["freteNome"] = [{ value: freteNome, disabled: disabled }];
      formObj["freteSobrenome"] = [
        { value: freteSobrenome, disabled: disabled },
      ];
      if (pedido.tipo.modalidadeFreteCF) {
        formObj["freteModalidade"] = [
          { value: freteModalidade, disabled: disabled },
          [Validators.required],
        ];
      }
    }

    this.form = this.formBuilder.group(formObj);
    this.pedido = pedido;
    this.subTotal = this.pedido.subtotal;
    this.valorTotal = this.pedido.total;
    this.acrescimoTotal = this.pedido.acrescimo;
    this.descontoTotal = this.pedido.desconto;
    this.impostosTotal = this.pedido.impostos;
    this.impostos = this.getImpostArray(this.pedido.itens);
    this.countPCDescFilial = 0;
    this.countPCAcresFilial = 0;
    this.mapProdutos = {};
    this.pedido.itens.forEach((i) => {
      this.itens.push(Object.assign({}, i));
      this.mapProdutos[i["produto"]] = null;
    });
    this.pedido.clientes.map((val) => {
      this.pedidoClientes.push({
        cliente: val.cliente,
        quantidade: val.quantidade,
        acrescimo: 0,
        acrescimoPercent: 0,
        desconto: 0,
        descontoPercent: 0,
        politicasComerciaisDesc: 0,
        politicasComerciaisAcres: 0,
      });
    });
    this.productService.findByMap(
      this.mapProdutos,
      function (resp) {
        if (resp) {
          this.mapProdutos = resp;
        }
      }.bind(this)
    );
    this.pedidoClienteSelected = { todos: true };
    this.pedido.clientes.forEach(
      (pc) => (this.pedidoClienteSelected[pc.cliente["id"]] = true)
    );
    //consultando as formas de pagamento e tipos de pedido

    this.atualizaFormasPagamento(disabled);
    this.atualizaPedidoItens();
  }

  private atualizaFormasPagamento(disabled = false) {
    let cliente = [];
    for (let key of Object.keys(this.pedidoClienteSelected)) {
      if (this.pedidoClienteSelected[key] && key != "todos")
        cliente.push(parseInt(key));
    }
    console.log(cliente);
    this.pedidoService.getFormasPagamento(
      this.pedidoService.pedido.id,
      cliente,
      function (resp) {
        console.log(resp);
        this.formaPagamento = resp;
        let formaPagamentoId = this.pedido["formaPagamento"]
          ? this.pedido["formaPagamento"]["id"]
          : "";
        this.form.controls.formaPagamento.setValue(formaPagamentoId);
        this.changeFormaPagamento();
        this.calculaFrete(this.form.controls.freteCepDestino.value);
        this.firstOpen = false;
        if (disabled) {
          this.form.controls.formaPagamento.disable();
        } else {
          this.form.controls.formaPagamento.enable();
        }
      }.bind(this)
    );
    this.usuarioService.getPedidoTipos(
      function (resp) {
        this.pedidoTiposUsuario = resp;
      }.bind(this)
    );
  }

  private getImpostArray(itens: OrderItem[]) {
    return [
      {
        tipo: "Ipi",
        valor: itens.length
          ? itens
              .map((a) => {
                return a.imp_valoripi * a.quantidade;
              })
              .reduce((a, b) => {
                return a + b;
              })
          : 0,
      },
      {
        tipo: "St",
        valor: itens.length
          ? itens
              .map((a) => {
                return a.imp_valorsubst * a.quantidade;
              })
              .reduce((a, b) => {
                return a + b;
              })
          : 0,
      },
      {
        tipo: "Fcp-st",
        valor: itens.length
          ? itens
              .map((a) => {
                return a.imp_valorfcpst * a.quantidade;
              })
              .reduce((a, b) => {
                return a + b;
              })
          : 0,
      },
      {
        tipo: "Total Impostos",
        valor: itens.length
          ? itens
              .map((a) => {
                return a.imp_valorimposto * a.quantidade;
              })
              .reduce((a, b) => {
                return a + b;
              })
          : 0,
      },
    ];
  }

  checkErrorEstoque(idProduto) {
    let formQuantidade = this.productService.getFormQuantidade(
      this.mapProdutos[idProduto],
      this.pedido
    );
    let formQuantidadeDigitada = {};
    let error =
      '<i class="icofont-check" style="color: green; font-size: 28px;"></i>';
    this.pedido.itens.forEach((i) => {
      if (i.produto == idProduto) {
        let id = this.productService.getIdQtd(i.cor, i.estampa, i.tamanho);
        if (formQuantidadeDigitada[id]) {
          formQuantidadeDigitada[id] += i.quantidade;
        } else {
          formQuantidadeDigitada[id] = i.quantidade;
        }
      }
    });
    for (let id of Object.keys(formQuantidadeDigitada)) {
      if (formQuantidadeDigitada[id] > formQuantidade[id]) {
        error =
          '<i class="icofont-close" style="color: red; font-size: 25px;"></i>';
        this.checkErrorEstoqueBool = true;
      }
    }
    return error;
  }

  checkErrorEstoque2(idProduto, cor, estampa, tamanho, quantidadeDigitada) {
    let quantidade = this.productService.getQuantidade(
      this.mapProdutos[idProduto],
      cor,
      estampa,
      tamanho,
      this.pedido.filial.id
    );
    let quantidadeShow = quantidade + "";
    if (
      this.SITE.quantidadeMaximaGrade > 0 &&
      quantidade >= this.SITE.quantidadeMaximaGrade
    ) {
      quantidadeShow = this.SITE.quantidadeMaximaGrade + "*";
    }
    if (this.pedido.tipo.validaEstoque) {
      let error = '<span style="color: blue;">' + quantidadeShow + "</span>";
      if (quantidadeDigitada > quantidade) {
        error =
          '<span style="color: red;"><b>' + quantidadeShow + "</b></span>";
        this.checkErrorEstoqueBool = true;
      }
      return error;
    } else {
      return quantidadeShow + "";
    }
  }

  changeFormaPagamento() {
    this.fretes = { receber: [], retirar: {} };
    if (this.tipoFrete == 2 && !this.firstOpen) {
      this.form.controls.freteId.setValue("");
      this.form.controls.freteValor.setValue("");
      this.form.controls.freteDescr.setValue("");
      this.freteSalvoDescr = "";
      this.errorFrete = false;
    }
    let proximoValor = 0;
    let fp = this.form.controls.formaPagamento.value;
    if (fp != "") {
      this.currentFormaPagamento = this.formaPagamento.filter(
        (find) => find.id == fp
      )[0];
      console.log(this.formaPagamento.filter((find) => find.id));
      if (this.currentFormaPagamento.cvvs == null) {
        this.cifPermited = true;
        this.fobPermited = true;
        this.onFreteModalidade(undefined);
      } else {
        this.cifPermited =
          this.currentFormaPagamento.cvvs.filter((cv) => cv.tipoFrete == "CIF")
            .length > 0;
        this.fobPermited =
          this.currentFormaPagamento.cvvs.filter((cv) => cv.tipoFrete == "FOB")
            .length > 0;
        if (
          !this.cifPermited &&
          this.currentFormaPagamento.possiveisCvs !== null
        ) {
          proximoValor =
            this.currentFormaPagamento.possiveisCvs[0].valorInicial;
        }
        if (this.cifPermited) {
          this.onFreteModalidade("0");
        } else {
          this.onFreteModalidade("1");
        }
      }
      this.freteShow = true;
    }
    this.atualizaValores();

    if (proximoValor != 0) {
      this.worningMessage = this.SITE.menssagemQuantoFaltaFreteGratis.replace(
        "$valor",
        "R$" +
          this.currencyPipe.transform(proximoValor - this.valorTotal, "", "")
      );
    }
  }
  setAviso() {
    document.getElementById("aviso").style.display = "none";
  }

  atualizaValores() {
    this.subTotal = this.pedido.subtotal;
    this.valorTotal = this.pedido.total;
    this.acrescimoTotal = this.pedido.acrescimo;
    this.descontoTotal = this.pedido.desconto;
    this.impostosTotal = this.pedido.impostos;
    this.impostos = this.getImpostArray(this.pedido.itens);
    this.countPCDescFilial = 0;
    this.countPCAcresFilial = 0;
    this.pedidoClientes.forEach((pc) => {
      pc.acrescimo = 0;
      pc.acrescimoPercent = 0;
      pc.desconto = 0;
      pc.descontoPercent = 0;
      pc.politicasComerciaisDesc = 0;
      pc.politicasComerciaisAcres = 0;
    });

    let politicas = [];
    let fpId = this.form.controls.formaPagamento.value;
    if (fpId != "") {
      let fp = this.formaPagamento.filter((f) => f.id == fpId)[0];
      if (fp.politicas) politicas = fp.politicas;
    }

    this.subTotal = 0;
    this.valorTotal = 0;
    this.acrescimoTotal = 0;
    this.descontoTotal = 0;
    this.impostosTotal = 0;
    this.impostos = [];
    if (politicas.length > 0 && this.SITE.tipoPoliticaComercial == 1) {
      this.politicaComercial01(politicas);
    }

    this.itens.forEach((i) => {
      for (let cliente of Object.keys(this.pedidoClienteSelected)) {
        if (
          cliente != "todos" &&
          i.cliente == cliente &&
          this.pedidoClienteSelected[cliente]
        ) {
          this.subTotal += i.preco * i.quantidade;
        }
      }
    });

    let frete = null;
    this.showFreteSum = false;
    if (
      this.form.controls.freteValor &&
      this.form.controls.freteValor.value &&
      (this.form.controls.freteModalidade == undefined ||
        this.form.controls.freteModalidade.value == "1")
    ) {
      frete = this.form.controls.freteValor.value;
      if (this.tipoFrete == 1) {
        frete = frete.replace(".", "").replace(",", ".");
      }
      if (frete > 0) {
        this.showFreteSum = true;
        this.valorTotal += Number(frete);
      }
    }

    this.itens.forEach((i) => {
      let _acrescimo = 0;
      let _desconto = 0;
      this.pedidoClientes.forEach((pc) => {
        if (i.cliente == pc.cliente.id) {
          _acrescimo += pc.acrescimoPercent;
          _desconto += pc.descontoPercent;
          pc.acrescimo += ((i.preco * _acrescimo) / 100) * i.quantidade;
          pc.desconto += ((i.preco * _desconto) / 100) * i.quantidade;
        }
      });
      i.acrescimo = (i.preco * _acrescimo) / 100;
      i.acrescimoPercent = _acrescimo;
      i.desconto = (i.preco * _desconto) / 100;
      i.descontoPercent = _desconto;
      let precoAcrescimoDesconto =
        (i.preco + i.acrescimo - i.desconto) * i.quantidade;
      let impostoCalc = {
        al_fcp_st: null,
        valor_fcp_st: null,
        base_ipi: null,
        al_ipi: null,
        base_sub: null,
        al_sub: null,
        valor_ipi: null,
        valor_sub: null,
        base_fcp_st: null,
      };
      impostoCalc = this.productService.calculaImposto(
        precoAcrescimoDesconto,
        i.imp_ipi,
        i.imp_alicms,
        i.imp_substicms,
        i.imp_alicmsorigem,
        i.imp_alfcpst,
        frete,
        this.subTotal
      );
      let valorImposto = this.productService.calculaValorImposto(
        precoAcrescimoDesconto,
        i.imp_ipi,
        i.imp_alicms,
        i.imp_substicms,
        i.imp_alicmsorigem,
        i.imp_alfcpst,
        frete,
        this.subTotal
      );
      let total = precoAcrescimoDesconto + valorImposto;
      i.total = total;
      i.imp_baseipi = impostoCalc.base_ipi / i.quantidade;
      i.imp_basesubst = impostoCalc.base_sub / i.quantidade;
      i.imp_basefcpst = impostoCalc.base_fcp_st / i.quantidade;
      i.imp_valoripi = impostoCalc.valor_ipi / i.quantidade;
      i.imp_valorsubst = impostoCalc.valor_sub / i.quantidade;
      i.imp_valorfcpst = impostoCalc.valor_fcp_st / i.quantidade;
      i.imp_valorimposto = valorImposto / i.quantidade;
      for (let cliente of Object.keys(this.pedidoClienteSelected)) {
        if (
          cliente != "todos" &&
          i.cliente == cliente &&
          this.pedidoClienteSelected[cliente]
        ) {
          this.acrescimoTotal += i.acrescimo * i.quantidade;
          this.descontoTotal += i.desconto * i.quantidade;
          this.impostosTotal += valorImposto * i.quantidade;
          this.impostos = this.getImpostArray(this.itens);
          this.valorTotal += i.total;
        }
      }
    });
  }

  //Política Comercial 01
  //Filial, (desconto / acréscimo)
  //Cliente, somente para o específico (desconto / acréscimo)
  //*Não acumula para Filial ou Cliente
  //*Uma política comercial (desconto) para cada
  //*Uma política comercial (acréscimo) para cada
  politicaComercial01(politicas) {
    for (var i = 0; i < politicas.length; i++) {
      let tipo = politicas[i].tipo;
      let tipoGerador = politicas[i].tipoGerador;
      let acerto = politicas[i].acerto;
      let gerador = politicas[i].gerador;
      let quantidade = politicas[i].quantidade;
      if (tipoGerador == "FL") {
        //FILIAL
        if (
          tipo == "DESCONTO" &&
          this.countPCDescFilial == 0 &&
          ((this.pedido.filial.id == gerador &&
            this.pedido.quantidade >= quantidade) ||
            (this.pedido.filial.id == gerador && !quantidade) ||
            (!gerador && this.pedido.quantidade >= quantidade) ||
            (!gerador && !quantidade))
        ) {
          //this.descontoPercent += acerto;
          this.pedidoClientes.forEach((pc) => {
            pc.descontoPercent += acerto;
          });
          this.countPCDescFilial++;
        } else if (
          tipo == "ACRESCIMO" &&
          this.countPCAcresFilial == 0 &&
          ((this.pedido.filial.id == gerador &&
            this.pedido.quantidade >= quantidade) ||
            (this.pedido.filial.id == gerador && !quantidade) ||
            (!gerador && this.pedido.quantidade >= quantidade) ||
            (!gerador && !quantidade))
        ) {
          //this.acrescimoPercent += acerto;
          this.pedidoClientes.forEach((pc) => {
            pc.acrescimoPercent += acerto;
          });
          this.countPCAcresFilial++;
        }
      } else if (tipoGerador == "CL") {
        //CLIENTE
        this.pedidoClientes.forEach((pc) => {
          if (
            tipo == "DESCONTO" &&
            pc.politicasComerciaisDesc == 0 &&
            ((pc.cliente["id"] == gerador && pc.quantidade >= quantidade) ||
              (pc.cliente["id"] == gerador && !quantidade) ||
              (!gerador && pc.quantidade >= quantidade) ||
              (!gerador && !quantidade))
          ) {
            pc.descontoPercent += acerto;
            pc.politicasComerciaisDesc++;
            //this.countPCClientes = true;
          } else if (
            tipo == "ACRESCIMO" &&
            pc.politicasComerciaisAcres == 0 &&
            ((pc.cliente["id"] == gerador && pc.quantidade >= quantidade) ||
              (pc.cliente["id"] == gerador && !quantidade) ||
              (!gerador && pc.quantidade >= quantidade) ||
              (!gerador && !quantidade))
          ) {
            pc.acrescimoPercent += acerto;
            pc.politicasComerciaisAcres++;
            //this.countPCClientes = true;
          }
        });
      }
    }
  }

  atualizaPedidoItens() {
    this.pedidoItens = [];
    this.pedidoItensQuantSum = 0;
    this.pedidoItensPrecoSum = 0;
    let mapClientes = {};
    for (let key of Object.keys(this.pedidoClienteSelected)) {
      if (key != "todos") {
        if (this.pedidoClienteSelected[key]) mapClientes[key] = 1;
      }
    }
    let map = { itens: [], quant: 0, preco: 0 };
    if (this.modo == 1) {
      map = this.pedidoService.mapaPedidoItensPorProduto(
        this.pedido.itens,
        mapClientes
      );
    } else {
      map = this.pedidoService.mapaPedidoItens(this.pedido.itens, mapClientes);
    }
    this.pedidoItens = map.itens;
    this.pedidoItensQuantSum = map.quant;
    this.pedidoItensPrecoSum = map.preco;
    this.changeFormaPagamento();
  }

  changePedidoClienteSelectedTodos() {
    if (this.pedidoClienteSelected.todos) {
      for (var i = 0; i < this.pedido.clientes.length; i++) {
        this.pedidoClienteSelected[this.pedido.clientes[i]["cliente"]["id"]] =
          true;
      }
    } else {
      for (var i = 0; i < this.pedido.clientes.length; i++) {
        this.pedidoClienteSelected[this.pedido.clientes[i]["cliente"]["id"]] =
          false;
      }
    }
    this.atualizaFormasPagamento();
    this.atualizaPedidoItens();
  }

  changePedidoClienteSelected() {
    this.pedidoClienteSelected.todos = true;
    for (var i = 0; i < this.pedido.clientes.length; i++) {
      if (
        !this.pedidoClienteSelected[this.pedido.clientes[i]["cliente"]["id"]]
      ) {
        this.pedidoClienteSelected.todos = false;
        break;
      }
    }
    this.atualizaFormasPagamento();
    this.atualizaPedidoItens();
  }

  onSubmit() {
    if (this.isCartaoCredito()) return;

    if (this.pedido.tipo.noCheckout) {
      this.salvarPedido();
    } else {
      this.finalizaPedido();
    }
  }

  finalizaPedido() {
    this.error = false;
    this.errorMsg = "";

    if (this.valorTotal < this.SITE.valorMinimoPedido) {
      this.error = true;
      this.errorMsg =
        "Valor do pedido inferior ao mínimo estipulado de " +
        this.SITE.valorMinimoPedido.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      +"!";
      return;
    }

    if (this.checkErrorEstoqueBool) {
      this.error = true;
      this.errorMsg = "Confira o estoque dos produtos!";
      return;
    }

    if (!this.form.valid) {
      this.error = true;
      this.errorMsg = "Confira o formulário corretamente!";
      return;
    }

    if (!(this.valorTotal > 0)) {
      this.error = true;
      this.errorMsg = "Valor total inválido!";
      return;
    }

    //Upload de arquivos pdfs, requerido, concatenação utilizada
    if (this.uploadFile == 1 && this.files.length == 0) {
      this.error = true;
      this.errorMsg = "Arquivo(s) do tipo PDF não informado(s)!";
      return;
    }

    let formValue = Object.assign({}, this.form.getRawValue());

    if (this.clienteIdErp) {
      this.pedidoService.pedido.clientes.forEach((pc) => {
        pc.idEnderecoErp = this.clienteIdErp[pc.cliente.id];
      });
    }

    //Corrigindo o valor do frete
    if (this.tipoFrete == 1) {
      formValue.freteValor = formValue.freteValor
        .replace(".", "")
        .replace(",", ".");
    }
    formValue.freteOferta = this.freteOferta;
    formValue.freteToken = this.freteToken;

    if (this.form.valid && !this.pedidoService.loading) {
      this.pedidoService.finalizaPedido(
        this.files,
        formValue,
        this.itens,
        function (resp) {
          if (resp.msg == "OK") {
            Swal.fire(
              "Pedido",
              "Finalizado com sucesso, em andamento!",
              "success"
            );
            this.router.navigate(["/pedidos"], {
              queryParams: { page: "EM_ANDAMENTO" },
            });
            this.logService.orderFinished(this.pedido.id);
            this.pedidoService.unSelectAndDeleteCheckout();
          }
        }.bind(this),
        function (error) {
          let obj = JSON.parse(error._body);
          if (obj && obj.msg) {
            this.error = true;
            this.errorMsg = obj.msg;
          }
        }.bind(this)
      );
    }
  }

  public salvarPedido() {
    this.error = false;
    this.errorMsg = "";

    if (this.checkErrorEstoqueBool) {
      this.error = true;
      this.errorMsg = "Confira o estoque dos produtos!";
      return;
    }

    //if(!this.form.valid && !this.errorFrete){
    if (!this.form.valid) {
      this.error = true;
      this.errorMsg = "Confira o formulário corretamente!";
      console.log(this.form.controls);
      return;
    }

    if (!(this.valorTotal > 0)) {
      this.error = true;
      this.errorMsg = "Valor total inválido!";
      return;
    }

    let formValue = Object.assign({}, this.form.value);

    if (this.clienteIdErp) {
      this.pedidoService.pedido.clientes.forEach((pc) => {
        pc.idEnderecoErp = this.clienteIdErp[pc.cliente.id];
      });
      //console.log('foiiiiii....');
    }

    //Corrigindo o valor do frete
    if (this.tipoFrete == 1) {
      formValue.freteValor = formValue.freteValor
        .replace(".", "")
        .replace(",", ".");
    }

    //if((this.form.valid || this.errorFrete) && !this.pedidoService.loading){
    if (this.form.valid && !this.pedidoService.loading) {
      this.pedidoService.salvaCheckout(
        formValue,
        this.itens,
        function (resp) {
          if (resp.msg == "OK") {
            Swal.fire("Pedido", "Salvo com sucesso!", "success");
            this.router.navigate(["/pedidos"], {
              queryParams: { page: "SALVO" },
            });
            this.pedidoService.unSelectAndDeleteCheckout();
            //this.pedidoService.select(resp.pedido);
            //this.notify(resp);
          }
        }.bind(this),
        function (error) {
          let obj = JSON.parse(error._body);
          if (obj && obj.msg) {
            this.error = true;
            this.errorMsg = obj.msg;
          }
        }.bind(this)
      );
    }
  }

  getLinkAprovaCliente(idPedidoCliente) {
    if (
      this.SITE.precoDivergenteTabelaMin != null &&
      this.SITE.precoDivergenteTabelaIdeal != null &&
      this.pedido.precoDivergenteStatus == 2
    ) {
      this.toastService.error("Preço divergente pendente", "Aprovação Cliente");
      return;
    }

    if (!this.pedido.quantidade) {
      this.toastService.error("Pedido vazio", "Aprovação Cliente");
      return;
    }

    this.pedidoService.getAprovaPedidoLink(
      idPedidoCliente,
      function (resp) {
        $("#inputLinkAprovaCliente").val(resp.link);
        let el = document.getElementById("inputLinkAprovaCliente") as any;
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.toastService.success(
          "Copiado para área de transferência",
          "Aprovação Cliente"
        );
      }.bind(this)
    );
  }

  onFreteModalidade(mod) {
    if (this.form.controls.freteModalidade)
      this.form.controls.freteModalidade.setValue(mod);

    this.atualizaValores();
  }

  onFrete(id: string) {
    console.log(typeof id);
    console.log(id);

    function decide(id: string, fretes1: FreteApiType) {
      const teste = fretes1.retirar;
      if (id === "205") return fretes1.retirar;
      if (id !== "205")
        return fretes1.receber.find((frete) => frete.id === Number(id));
    }
    const frete = decide(id, this.fretes);

    //&& !frete.error
    if (frete.id > 0) {
      this.form.controls.freteId.setValue(frete.id + "");
      this.form.controls.freteValor.setValue(frete.valor);
      let freteDescr = frete.label;
      if (frete.labelPrazo) {
        freteDescr += ", " + frete.labelPrazo;
      }
      if (frete.valor) {
        freteDescr += ", R$" + this.currencyPipe.transform(frete.valor, "", "");
      }
      if (frete.oferta) {
        this.freteOferta = frete.oferta;
      }
      if (frete.token) {
        this.freteToken = frete.token;
      }
      this.form.controls.freteDescr.setValue(freteDescr);
      this.atualizaValores();
    }
  }

  maskMoney(e) {
    let v = e.target.value;
    v = v.replace(/\D/g, "");
    v = v.replace(/[0-9]{12}/, "inválido");
    v = v.replace(/(\d{1})(\d{8})$/, "$1.$2");
    v = v.replace(/(\d{1})(\d{5})$/, "$1.$2");
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    e.target.value = v;
    this.form.controls.freteValor.setValue(v);
  }

  maskMoneyBlur() {
    this.atualizaValores();
  }

  removeItem() {
    if (!this.idDelete) return;

    this.pedidoService.removeCartItem(
      this.idDelete,
      function (resp) {
        this.notify(resp);
        this.pedidoService.select(resp);
      }.bind(this)
    );
  }

  removeItemModo2() {
    if (!this.idDeleteModo2) return;

    this.pedidoService.codigoBarrasDel(
      this.pedido.id,
      this.idDeleteModo2,
      function (resp) {
        this.notify(resp);
        this.pedido = resp;
        this.pedidoService.select(resp);
        this.toastService.success("Item removido!");
      }.bind(this)
    );
  }

  confirmDel(id) {
    this.idDelete = id;
  }

  confirmDelModo2(id) {
    this.idDeleteModo2 = id;
  }

  isCartaoCredito() {
    return false;
    return (
      this.currentFormaPagamento &&
      this.currentFormaPagamento.descricao.includes("/")
    );
  }
  mostrarTabela() {
    let element = document.getElementById("btn-retirar");
    let element2 = document.getElementById("calc");
    document.getElementById("retirar").style.display = "";

    document.getElementById("receber").style.display = "none";
    element.style.backgroundColor = "#1c5c46";
    element2.style.backgroundColor = "";
  }
}
