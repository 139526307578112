import { Component, OnInit, Input } from "@angular/core";
declare var $: any;

@Component({
  selector: "slider-superior",
  templateUrl: "./slider-superior.component.html",
  styleUrls: ["./slider-superior.component.css"],
})
export class SliderSuperiorComponent implements OnInit {
  @Input() ativo: Boolean = true;
  @Input() values: Array<String>;
  @Input() interval: Number = 3000;
  @Input() backgroundColor: String = "#000000";
  @Input() fontSize: Number = 16;
  @Input() fontColor: String = "#ffffff";

  ngOnInit() {
    let interval = this.interval;
    $(function () {
      $("#gcc-slider-superior").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: interval,
        arrows: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        responsive: [
          {
            breakpoint: 1140,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  }
}
