import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { defineLocale } from "ngx-bootstrap/chronos";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ptBrLocale } from "ngx-bootstrap/locale";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { NgxMaskModule } from "ngx-mask";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { AppRoutingModule, routingComponents } from "./app.routes";
import { CartComponent } from './components/cart/cart.component';
import { CreditCardComponent } from "./components/credit-card/credit-card.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { NewsLetterComponent } from "./components/news-letter/news-letter.component";
import { NovoClienteComponent } from "./components/novo-cliente/novo-cliente.component";
import { SimpleHtmlModule } from "./components/simple-html/simple-html.module";
import { SliderImagemComponent } from "./components/slider-imagem/slider-imagem.component";
import { SliderPrincipalComponent } from "./components/slider-principal/slider-principal.component";
import { SliderProdutoComponent } from "./components/slider-produto/slider-produto.component";
import { SliderSuperiorComponent } from "./components/slider-superior/slider-superior.component";
import { DirectiveModule } from "./directive/directives.module";
import { CatalogModule } from "./modules/catalog/catalog.module";
import { MaterialMarketingModule } from "./pages/material-marketing/material-marketing.module";
import { PageNotfoundComponent } from "./pages/page-notfound/page-notfound.component";
import { DateBRAPipe } from "./pipes/dateBRA.pipe";
import { FilterByKeyPipe } from "./pipes/filterByKey.pipe";
import { InitProvider } from "./services/init-provider";
import { LoginGuard } from "./services/loginguard";
defineLocale("pt-br", ptBrLocale);
registerLocaleData(localePt, "pt-BR");

export function filtroProviderFactory(provider: InitProvider) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    SliderPrincipalComponent,
    SliderSuperiorComponent,
    HeaderComponent,
    FooterComponent,
    PageNotfoundComponent,
    SliderImagemComponent,
    SliderProdutoComponent,
    FilterByKeyPipe,
    DateBRAPipe,
    NovoClienteComponent,
    NewsLetterComponent,
    CreditCardComponent,
    CartComponent
  ],
  imports: [
    CatalogModule,
    MaterialMarketingModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpModule,
    FormsModule,
    SimpleHtmlModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SweetAlert2Module.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
    DirectiveModule
  ],
  providers: [
    LoginGuard,
    InitProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: filtroProviderFactory,
      deps: [InitProvider],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "pt-BR" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
