import { Component, Input } from "@angular/core";
import { SliderPrincipalValue } from "../components";
import { Configuracoes } from "src/app/entities/configuracoes";
declare var $: any;

@Component({
  selector: "slider-principal",
  templateUrl: "./slider-principal.component.html",
  styleUrls: ["./slider-principal.component.css"],
})
export class SliderPrincipalComponent {
  @Input() interval: Number = 3000;
  @Input() fade: Boolean = false;
  @Input() ballIndicator: Boolean = false;
  @Input() mousePause: Boolean = false; // hover | false
  _values: Array<SliderPrincipalValue>;
  @Input()
  set values(arr: Array<SliderPrincipalValue>) {
    if (arr) {
      this._values = arr;
      this.refresh();
    }
  }
  get values(): Array<SliderPrincipalValue> {
    return this._values;
  }

  public API_IMAGENS: String;

  refresh() {
    $(".carousel").carousel("dispose");
    $(".carousel").carousel({
      interval: this.interval,
      pause: this.mousePause ? "hover" : false,
    });
  }

  constructor() {
    this.API_IMAGENS = Configuracoes.API_IMAGENS;
  }
}
