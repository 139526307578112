import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Configuracoes } from 'src/app/entities/configuracoes';

@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.css']
})
export class ProductImageComponent implements OnInit {

  @Input()
  public product: any;

  @Input()
  public height: string;

  @Input()
  public produtoDescrHeight: string;

  @Output()
  public onAdd = new EventEmitter<number>();

  public API_IMAGENS: string;
  public site: any;
  public quantity: number;

  constructor(private router: Router) {
    this.API_IMAGENS = Configuracoes.API_IMAGENS;
    this.site = Configuracoes.SITE;
  }
  ngOnInit(): void {
    this.quantity = this.product.quantidade;
  }

  public changeItemQuantity(value: number) {
    this.quantity = value;
    this.onAdd.emit(value);
  }
}
