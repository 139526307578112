import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { DeactvateGuardComponent } from "../entities/deactvateguardcomponent";

@Injectable({
  providedIn: "root",
})
export class DeactivateGuard implements CanDeactivate<DeactvateGuardComponent> {
  canDeactivate(
    component: DeactvateGuardComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // you can just return true or false synchronously
    //if (expression === true) {
    //return true;
    //}

    return component.canDeactivate();
    // or, you can also handle the guard asynchronously, e.g.
    // asking the user for confirmation.
    //return component.dialogService.confirm('Discard changes?');
  }
}
