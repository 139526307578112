import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { PedidoService } from "src/app/services/pedido-service";
import { Order } from "src/app/entities/order";
import { ToastrService } from "ngx-toastr";
import { ConfiguracoesSite } from "src/app/entities/configuracoesSite";
import { Configuracoes } from "src/app/entities/configuracoes";
import { ProductService } from "src/app/services/product-service";
import { CurrencyPipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { UsuarioService } from "src/app/services/usuario-service";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { BsLocaleService, DateFormatter } from "ngx-bootstrap/datepicker";
import { SecurityService } from "src/app/services/security-service";
import { DownloadImagesService } from "src/app/services/download-images.service";
declare var $: any;

@Component({
  selector: "app-page-pedidos",
  templateUrl: "./page-pedidos.component.html",
  styleUrls: ["./page-pedidos.component.css"],
})
export class PagePedidosComponent {
  SITE: ConfiguracoesSite;

  public page: String = "ABERTO";
  public pedidoDelete: Order;
  public pedidoCopy: Order;
  public pedidos: Order[] = [];
  public pedido: any;
  public pedidoItens = [];
  public pedidoClienteSelected = { todos: true };
  public empty: boolean = false;
  public subTotal: number = 0;
  public valorTotal: number = 0;
  public acrescimoTotal: number = 0;
  public descontoTotal: number = 0;
  public impostosTotal: number = 0;
  public quantidadeTotal: number = 0;
  public formSearch: FormGroup;
  usuarios: Observable<any[]>;
  clientes: Observable<any[]>;
  typingUsuario: string;
  typingCliente: string;
  public ranges: any = [
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
      ],
      label: "Últimos 7 Dias",
    },
    {
      value: [
        new Date(),
        new Date(new Date().setDate(new Date().getDate() + 7)),
      ],
      label: "Próximos 7 Dias",
    },
    {
      value: [
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        new Date(),
      ],
      label: "Último mês",
    },
    {
      value: [
        new Date(),
        new Date(new Date().setMonth(new Date().getMonth() + 1)),
      ],
      label: "Próximo mês",
    },
    {
      value: [
        new Date(new Date().setMonth(new Date().getMonth() - 6)),
        new Date(),
      ],
      label: "Últimos 6 meses",
    },
    {
      value: [
        new Date(),
        new Date(new Date().setMonth(new Date().getMonth() + 6)),
      ],
      label: "Próximos 6 meses",
    },
  ];

  public pageable;
  public pageNumber = 1;
  public mapItens;
  public mapProdutos;
  public tablePedidoView;
  public tablePedidoAberto;
  private currencyPipe: CurrencyPipe = new CurrencyPipe("pt-BR");
  private format = new DateFormatter();
  statusERPs = [];
  public isGestor = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public pedidoService: PedidoService,
    public productService: ProductService,
    private usuarioService: UsuarioService,
    private formBuilder: FormBuilder,
    private localeService: BsLocaleService,
    private toastService: ToastrService,
    private downloadService: DownloadImagesService,
    private securityService: SecurityService
  ) {
    this.SITE = Configuracoes.SITE;
    this.isGestor = this.securityService.isType("GESTOR");
    this.localeService.use("pt-br");
    this.formSearch = this.formBuilder.group({
      usuario: [""],
      cliente: [""],
      data: [[]],
      statusERP: [""],
    });
    this.route.queryParams.subscribe((params) => {
      if (params["page"]) this.page = params["page"];
      else this.page = "ABERTO";

      /*$('#usuario').val('');
      $('#usuario').removeClass('is-valid is-invalid');
      $('#cliente').val('');
      $('#cliente').removeClass('is-valid is-invalid');
      this.formSearch.reset();*/
      this.formSearch.controls.statusERP.setValue("");
      this.statusERPs = [];
      this.pedidos = [];
      if (this.pageNumber == 1) {
        this.init();
      } else {
        this.pageNumber = 1;
      }
    });
    this.usuarios = Observable.create((observer: any) => {
      if (!this.usuarioService.loading) {
        this.usuarioService.getUsuarios(
          this.typingUsuario,
          function (resp) {
            observer.next(resp);
          }.bind(this)
        );
      }
    });
    this.clientes = Observable.create((observer: any) => {
      if (!this.usuarioService.loading) {
        this.usuarioService.getClientes(
          this.typingCliente,
          function (resp) {
            observer.next(resp);
          }.bind(this)
        );
      }
    });
  }

  async download(p: Order) {
    for (let index = 0; index < p.itens.length; index++) {
      const i = p.itens[index];
      const resp = await this.productService.getPhotosPromesse(i.produto);
      let images = resp.map(
        (r) => Configuracoes.API_IMAGENS + "produtos/" + r.nome
      );
      this.downloadService.append(images);
    }
    this.downloadService.download(`fotos_pedido ${p.id}`);
  }

  init() {
    let form = { page: this.pageNumber - 1 };
    if (this.formSearch.value.cliente) {
      form["cliente"] = this.formSearch.value.cliente;
    }
    if (this.formSearch.value.usuario) {
      form["usuario"] = this.formSearch.value.usuario;
    }
    if (
      this.formSearch.value.data &&
      this.formSearch.value.data[0] &&
      this.formSearch.value.data[1]
    ) {
      form["dataInicial"] = this.format.format(
        this.formSearch.value.data[0],
        "DD/MM/YYYY",
        "en"
      );
      form["dataFinal"] = this.format.format(
        this.formSearch.value.data[1],
        "DD/MM/YYYY",
        "en"
      );
    }
    if (this.formSearch.value.statusERP) {
      form["statusERP"] = this.formSearch.value.statusERP;
    }
    if (this.page == "SALVO") {
      this.pedidoService.listaPedido(form, this.callback.bind(this), "SALVO");
    }
    if (this.page == "TODOS") {
      form["tipo"] = this.page;
      this.pedidoService.listaPedido(form, this.callback.bind(this), false);
    }

    if (this.page == "ABERTO") {
      if (this.SITE.salvaCheckout) {
        this.pedidoService.listaPedido(
          form,
          this.callback.bind(this),
          "ABERTO"
        );
      } else {
        this.pedidoService.listaPedido(form, this.callback.bind(this), false);
        // this.pedidoService.listaPedido(form, this.callback.bind(this), 'ABERTO');
      }
    } else if (this.page == "EM_ANDAMENTO" || this.page == "CONCLUÍDO") {
      form["tipo"] = this.page;
      this.pedidoService.getStatusERP(
        form,
        function (resp) {
          console.log(resp);
          this.statusERPs = resp;
        }.bind(this)
      );
      this.pedidoService.listaPedidoCliente(
        form,
        this.callback.bind(this),
        function (error) {
          this.empty = true;
        }.bind(this)
      );
    }
  }

  pageChanged(e: any): void {
    this.pageNumber = e.page;
    this.init();
  }

  callback(p) {
    console.log(p);
    this.pageable = p;
    this.pedidos = p.content;
    if (this.pedidos.length > 0) this.empty = false;
    else this.empty = true;
  }

  onSubmitSearch() {
    if (this.pageNumber == 1) {
      this.init();
    } else {
      this.pageNumber = 1;
    }
  }

  onSelectUsuario(event: TypeaheadMatch): void {
    this.formSearch.controls.usuario.setValue(event.item.id);
    $("#usuario").blur();
    $("#usuario").removeClass("is-valid is-invalid");
    $("#usuario").addClass("is-valid");
    $("#btnFormSearch").focus();
  }

  typeaheadNoResultsUsuario(event: boolean): void {
    this.formSearch.controls.usuario.setValue("");
    $("#usuario").removeClass("is-valid is-invalid");
    $("#usuario").addClass("is-invalid");
  }

  onSelectCliente(event: TypeaheadMatch): void {
    console.log(this.typingCliente, event.item);
    this.formSearch.controls.cliente.setValue(event.item.id);
    $("#cliente").blur();
    $("#cliente").removeClass("is-valid is-invalid");
    $("#cliente").addClass("is-valid");
    $("#btnFormSearch").focus();
  }

  typeaheadNoResults(event: boolean): void {
    //if(event){
    this.formSearch.controls.cliente.setValue("");
    $("#cliente").removeClass("is-valid is-invalid");
    $("#cliente").addClass("is-invalid");
    //}
  }

  showPedido(p: Order) {
    this.subTotal = 0;
    this.valorTotal = 0;
    this.acrescimoTotal = 0;
    this.descontoTotal = 0;
    this.impostosTotal = 0;
    this.quantidadeTotal = 0;
    this.pedidoClienteSelected = { todos: true };
    this.pedido = p;
    console.log(p);
    this.pedido.clientes.forEach(
      (pc) => (this.pedidoClienteSelected[pc.cliente["id"]] = true)
    );
    this.atualizaPedidoItens();
  }

  showPedidoFinalizado(p: any) {
    this.pedido = p;
    console.log(p);
    this.mapaItensEProdutos();
    this.tablePedidoView = "";
    this.productService.findByMap(
      this.mapProdutos,
      function (resp) {
        if (resp) {
          let refs = [];
          this.mapProdutos = resp;
          for (let key of Object.keys(this.mapProdutos)) {
            refs.push({
              id: this.mapProdutos[key].id,
              ref: this.mapProdutos[key].referencia,
            });
          }
          refs.sort(this.sort1);
          refs.forEach((r) => {
            this.tablePedidoView += this.table1(
              this.mapProdutos[r.id],
              this.mapItens[r.id]
            );
          });
        }
      }.bind(this)
    );
  }

  atualizaPedidoItens() {
    this.tablePedidoAberto = "";
    this.subTotal = 0;
    this.valorTotal = 0;
    this.acrescimoTotal = 0;
    this.descontoTotal = 0;
    this.impostosTotal = 0;
    this.quantidadeTotal = 0;
    let mapClientes = {};
    for (let key of Object.keys(this.pedidoClienteSelected)) {
      if (key != "todos") {
        if (this.pedidoClienteSelected[key]) mapClientes[key] = 1;
      }
    }

    let itens = [];
    this.pedido.itens.forEach((i) => {
      if (mapClientes[i.cliente]) {
        this.quantidadeTotal += i.quantidade;
        let naoExiste = true;
        itens.forEach((x) => {
          if (
            x.produto == i.produto &&
            x.cor == i.cor &&
            x.estampa == i.estampa &&
            x.tamanho == i.tamanho
          ) {
            naoExiste = false;
            x.quantidade += i.quantidade;
            x.total += i.total;
          }
        });
        if (naoExiste) itens.push(Object.assign({}, i));
      }
    });
    this.mapaItensEProdutosPedidoAberto(itens);
    this.productService.findByMap(
      this.mapProdutos,
      function (resp) {
        if (resp) {
          let refs = [];
          this.mapProdutos = resp;
          for (let key of Object.keys(this.mapProdutos)) {
            refs.push({
              id: this.mapProdutos[key].id,
              ref: this.mapProdutos[key].referencia,
            });
          }
          refs.sort(this.sort1);
          refs.forEach((r) => {
            this.tablePedidoAberto += this.table1(
              this.mapProdutos[r.id],
              this.mapItens[r.id]
            );
          });
        }
      }.bind(this)
    );

    if (this.pedido.clientes) {
      this.pedido.clientes.forEach((c) => {
        if (this.pedidoClienteSelected[c.cliente.id]) {
          this.subTotal += c.subtotal;
          this.acrescimoTotal += c.acrescimo;
          this.descontoTotal += c.desconto;
          this.impostosTotal += c.impostos;
          this.valorTotal += c.total;
        }
      });
    }
  }

  getLinkAprovaCliente(p, idPedidoCliente) {
    if (
      this.SITE.precoDivergenteTabelaMin != null &&
      this.SITE.precoDivergenteTabelaIdeal != null &&
      p.precoDivergenteStatus == 2
    ) {
      this.toastService.error("Preço divergente pendente", "Aprovação Cliente");
      return;
    }

    if (!p.quantidade) {
      this.toastService.error("Pedido vazio", "Aprovação Cliente");
      return;
    }

    this.pedidoService.getAprovaPedidoLink(
      idPedidoCliente,
      function (resp) {
        $("#inputLinkAprovaCliente").val(resp.link);
        let el = document.getElementById("inputLinkAprovaCliente") as any;
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.toastService.success(
          "Copiado para área de transferência",
          "Aprovação Cliente"
        );
      }.bind(this)
    );
  }

  confirmCopy(p: Order) {
    this.pedidoCopy = p;
  }

  confirmDel(p: Order) {
    this.pedidoDelete = p;
  }

  selectPedido(p: Order) {
    if (!this.pedidoService.confirmaTrocaPedido(p))
      return;
    if (p.salvoCheckout) {
      Swal.fire({
        title: "Confirma edição?",
        icon: "warning",
        html: "Pedido salvo anteriormente, será necessário reconfirmar o checkout",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "Editar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.pedidoService.editSalvaCheckout(
            p.id,
            function (resp) {
              this.pedidoService.select(resp);
              this.toastService.info("Pedido #" + p.id + " selecionado!");

              if (Configuracoes.modoVisualizacaoCatalogo)
                window.localStorage.setItem(
                  "visualizacaoModo",
                  Configuracoes.modoVisualizacaoCatalogo
                );

              this.router.navigate(["/catalogo"]);
            }.bind(this)
          );
        }
      });
    } else {
      this.pedidoService.select(p);
      this.toastService.info("Pedido #" + p.id + " selecionado!");

      if (Configuracoes.modoVisualizacaoCatalogo)
        window.localStorage.setItem(
          "visualizacaoModo",
          Configuracoes.modoVisualizacaoCatalogo
        );

      this.router.navigate(["/catalogo"]);
    }
  }

  selectPedidoCheckout(p: Order) {
    if (!this.pedidoService.confirmaTrocaPedido(p))
      return;
    this.pedidoService.select(p);
    this.toastService.info("Pedido #" + p.id + " selecionado!");
    this.router.navigate(["/checkout"]);
  }

  copyPedido() {
    this.pedidoService.copy(
      this.pedidoCopy,
      function () {
        this.init();
        Swal.fire("Pedido", "Copiado com sucesso", "success");
      }.bind(this)
    );
  }

  redirectCopyPedido(p: Order) {
    this.router.navigate(["/novo-pedido"], { state: p });
  }

  printPedidoById(pcId) {
    this.pedidoService.getPedidoCliente(
      pcId,
      function (resp) {
        this.printPedido(resp);
      }.bind(this)
    );
  }

  printPedidoPdf(pcId) {
    const headers = new Headers({
      Branch: Configuracoes.API_BRANCH,
      Authorization: "Bearer " + window.localStorage.getItem("token"),
    });
    fetch(
      `${Configuracoes.API2}impressoes/pedido?pedido=${pcId}&layout=${Configuracoes.layout}`,
      {
        method: "GET",
        headers,
      }
    )
      .then((response) => {
        console.log(response);
        return response.blob();
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  printPedidoExcel(p) {
    console.log(p);
    let cliente = p.cliente;
    let clienteStr =
      cliente[Configuracoes.SITE.identificacaoCliente] + " - " + cliente.nome;
    if (cliente.documento) {
      clienteStr += ", " + cliente.documento;
    }
    let rows = [
      ["CLIENTE", clienteStr],
      [""],
      [
        "REFERENCIA",
        "PRODUTO",
        "COR",
        "ESTAMPA",
        "TAMANHO",
        "QUANTIDADE",
        "PRECO",
        "TOTAL",
      ],
    ];
    p.itens.forEach((i) => {
      rows.push([
        i.produtoReferencia,
        i.produtoDescricao,
        i.corDescricao,
        i.estampa,
        i.tamanho,
        i.quantidade,
        i.preco,
        i.total,
      ]);
    });

    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach(function (rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "pedido_" + p.id + ".csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  printPedido(p) {
    this.pedido = p;
    this.mapaItensEProdutos();
    //https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
    //http://detectmobilebrowsers.com/
    const isMobile =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        navigator.userAgent || navigator.vendor
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        (navigator.userAgent || navigator.vendor).substr(0, 4)
      );
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    console.log(isMobile, isIEOrEdge, isChrome);

    this.productService.findByMap(
      this.mapProdutos,
      function (resp) {
        if (resp) {
          this.mapProdutos = resp;
          if (isMobile || isIEOrEdge) {
            this.pedidoService.pedidoPDF(
              this.pedido,
              this.mapProdutos,
              this.mapItens,
              "download"
            );
          } else {
            $("#printPedidoModal").modal("show");
            $("#printPedidoModal .modal-body").empty();
            if (isChrome) {
              $("#printPedidoModal .modal-body").append(
                '<embed src="' +
                  this.pedidoService.pedidoPDF(
                    this.pedido,
                    this.mapProdutos,
                    this.mapItens,
                    "bloburi"
                  ) +
                  '" style="width:100%;height:842pt;"></embed>'
              );
            } else {
              let obj = document.createElement("object");
              obj.style.width = "100%";
              obj.style.height = "842pt";
              obj.type = "application/pdf";
              obj.data = this.pedidoService.pedidoPDF(
                this.pedido,
                this.mapProdutos,
                this.mapItens,
                "data"
              );
              $("#printPedidoModal .modal-body").append(obj);
            }
            console.log("report...");
          }
        }
      }.bind(this)
    );
  }

  mapaItensEProdutos() {
    this.mapItens = {};
    this.pedido.itens.forEach((i) => {
      if (this.mapItens[i.produto]) {
        this.mapItens[i.produto].push(i);
      } else {
        this.mapItens[i.produto] = [i];
      }
    });
    this.mapProdutos = {};
    this.pedido.itens.forEach((i) => {
      this.mapProdutos[i["produto"] as any] = null;
    });
  }

  mapaItensEProdutosPedidoAberto(itens) {
    this.mapItens = {};
    itens.forEach((i) => {
      if (this.mapItens[i.produto]) {
        this.mapItens[i.produto].push(i);
      } else {
        this.mapItens[i.produto] = [i];
      }
    });
    this.mapProdutos = {};
    itens.forEach((i) => {
      this.mapProdutos[i["produto"] as any] = null;
    });
  }

  changePedidoClienteSelectedTodos() {
    if (this.pedidoClienteSelected.todos) {
      for (var i = 0; i < this.pedido.clientes.length; i++) {
        this.pedidoClienteSelected[this.pedido.clientes[i]["cliente"]["id"]] =
          true;
      }
    } else {
      for (var i = 0; i < this.pedido.clientes.length; i++) {
        this.pedidoClienteSelected[this.pedido.clientes[i]["cliente"]["id"]] =
          false;
      }
    }
    this.atualizaPedidoItens();
  }

  changePedidoClienteSelected() {
    this.pedidoClienteSelected.todos = true;
    for (var i = 0; i < this.pedido.clientes.length; i++) {
      if (
        !this.pedidoClienteSelected[this.pedido.clientes[i]["cliente"]["id"]]
      ) {
        this.pedidoClienteSelected.todos = false;
        break;
      }
    }
    this.atualizaPedidoItens();
  }

  table1(produto, itens) {
    let mapCor = {};
    itens.forEach((i) => {
      if (mapCor[i.cor]) {
        mapCor[i.cor].push(i);
      } else {
        mapCor[i.cor] = [i];
      }
    });
    let head = "<tr><td>COR</td>";
    let rows = [];
    let totalTamanhos = {};
    let totalQtde = 0;
    let totalPreco = 0;
    let totalTotal = 0;
    for (let key of Object.keys(mapCor)) {
      let grade = "<tr><td>" + mapCor[key][0].corDescricao + "</td>";
      let qtde = 0;
      let total = 0;
      produto.tamanhos.forEach((t) => {
        let tamanho = 0;
        mapCor[key].forEach((mC) => {
          if (mC.tamanho == t.tamanho) {
            tamanho = mC.quantidade;
            qtde += mC.quantidade;
            total += mC.total;
            if (totalTamanhos[t.tamanho]) {
              totalTamanhos[t.tamanho] += mC.quantidade;
            } else {
              totalTamanhos[t.tamanho] = mC.quantidade;
            }
          }
        });
        grade += "<td>" + tamanho + "</td>";
      });
      totalQtde += qtde;
      totalPreco += total / qtde;
      totalTotal += total;
      grade += "<td>" + qtde + "</td>";
      grade +=
        "<td>" + this.currencyPipe.transform(total / qtde, "", "") + "</td>";
      grade +=
        "<td>" + this.currencyPipe.transform(total, "", "") + "</td></tr>";
      rows.push(grade);
    }
    let grade = "<tr><td></td>";
    produto.tamanhos.forEach((t) => {
      grade +=
        "<td>" +
        (totalTamanhos[t.tamanho] ? totalTamanhos[t.tamanho] : "") +
        "</td>";
    });
    grade += "<td><b>" + totalQtde + "</b></td>";
    grade +=
      "<td><b>" + this.currencyPipe.transform(totalPreco, "", "") + "</b></td>";
    grade +=
      "<td><b>" +
      this.currencyPipe.transform(totalTotal, "", "") +
      "</b></td></tr>";
    rows.push(grade);
    produto.tamanhos.forEach((t) => {
      head += "<td>" + t.tamanho + "</td>";
    });
    head += "<td>QTDE</td>";
    head += "<td>PREÇO</td>";
    head += "<td>TOTAL</td></tr>";
    return `<div class="table-responsive">
      <table class="table table-hover table-condensed table-striped table-sm table-responsive-xs">
        <thead class="table-head">
            <tr>
                <th colspan="5">${produto.referencia} - ${
      produto.descricao
    }</th>
            </tr>
        </thead>
        <tbody>
            ${head}
            ${rows.join("")}
        </tbody>
      </table>
    </div>`;
  }

  deletePedido() {
    this.pedidoService.unSelectAndDelete(
      this.pedidoDelete,
      function (resp) {
        this.init();
        Swal.fire("Pedido", "Excluído com sucesso", "success");
      }.bind(this),
      function (error) {
        let obj = JSON.parse(error._body);
        if (obj && obj.msg) {
          Swal.fire("Pedido", obj.msg, "warning");
        }
      }.bind(this)
    );
  }

  sort1(a, b) {
    if (a.ref.toLowerCase() > b.ref.toLowerCase()) {
      return 1;
    } else if (a.ref.toLowerCase() < b.ref.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  }

  public redirectToRastreio(p: Order) {
    if (p.freteRastreios.length)
      window.open(p.freteRastreios[0].rastreio, "_blank").focus();
    else this.toastService.warning("Sem rastreio associado ao pedido");
  }
}
