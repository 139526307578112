import { Injectable } from '@angular/core';
import { OrderItem } from '../entities/orderItem';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private observers: CartObserver[] = [];

  constructor() { }

  registerObserver(observer: CartObserver) {
    this.observers.push(observer);
  }

  removeObserver(observer: CartObserver) {
    const index = this.observers.indexOf(observer);
    const removed = this.observers.splice(index, 1);
    console.log("Removed by Cart listeners ", removed)
  }

  addItems(items: OrderItem[]) {
    for (let observer of this.observers) {
      observer.addItems(items);
    }
  }
}

export interface CartObserver {
  addItems(items: OrderItem[]);
}
