import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Configuracoes } from "src/app/entities/configuracoes";
import { ConfiguracoesSite } from "src/app/entities/configuracoesSite";
import { NewsletterService } from "src/app/services/newsletter-service";
import Swal from "sweetalert2";

@Component({
  selector: "app-news-letter",
  templateUrl: "./news-letter.component.html",
  styleUrls: ["./news-letter.component.css"],
})
export class NewsLetterComponent implements OnInit {
  form: FormGroup;
  SITE: ConfiguracoesSite;
  imageUrl: string;

  constructor(private newsletterService: NewsletterService) {
    this.imageUrl = Configuracoes.API_IMAGENS + "newsletter.png";
    this.SITE = Configuracoes.SITE;
    this.form = new FormGroup({
      nome: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    this.newsletterService.post(
      this.form.controls["nome"].value,
      this.form.controls["email"].value,
      this.callback
    );
  }

  private callback(response) {
    Swal.fire("Sucesso", "Newsletter cadastrado com sucesso", "success");
  }
}
