import { Injectable } from "@angular/core";
import { HttpService } from "./http-service";
import { ToastrService } from "ngx-toastr";
import { Http } from "@angular/http";

@Injectable({
  providedIn: "root",
})
export class UsuarioService extends HttpService {
  constructor(public http: Http, public toastService: ToastrService) {
    super(http, toastService);
  }

  getInfoPageHtml(id, callback) {
    this.get("usuario/infoPageHtml/" + id, callback);
  }

  cidadesPorUf(uf, callback) {
    this.get("usuario/cidadesPorUf/" + uf, callback);
  }

  cadastraCliente(registro: boolean, form, callback, callbackError) {
    if (registro) {
      this.post("principal/cadastraCliente", form, callback, callbackError);
    } else {
      this.post("usuario/cadastraCliente", form, callback, callbackError);
    }
  }

  getUsuarios(busca, callback) {
    this.post("usuario/getUsuarios", { busca: busca }, callback);
  }

  getClientes(busca, callback) {
    this.post("usuario/getClientes", { busca: busca }, callback);
  }

  getTabelas(callback) {
    this.get("usuario/getTabelas", callback);
  }

  getPedidoTipos(callback) {
    this.get("usuario/getPedidoTipos", callback);
  }

  getBoletos(callback) {
    this.get("usuario/getBoletos", callback);
  }

  boleto(id, callback) {
    this.post("usuario/boleto", { id: id }, callback);
  }

  /**
   * Altera a senha do usuário logado
   * @param senhaAntiga Senha antiga
   * @param senhaNova Senha nova
   * @param callback Retorno caso sucesso
   * @param callbackError Retorno caso falha
   */
  updatePassword(senhaAntiga, senhaNova, callback, callbackError) {
    let obj = { senhaAntiga: senhaAntiga, senhaNova: senhaNova };
    this.post("usuario/updatesenha", obj, callback, callbackError);
  }
}
