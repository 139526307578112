import { Component, OnInit } from "@angular/core";

@Component({
  selector: "page-notfound",
  templateUrl: "./page-notfound.component.html",
  styleUrls: ["./page-notfound.component.css"],
})
export class PageNotfoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
