import { CurrencyPipe } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Configuracoes } from "src/app/entities/configuracoes";
import { ConfiguracoesSite } from "src/app/entities/configuracoesSite";
import { PedidoService } from "src/app/services/pedido-service";
import Swal from "sweetalert2";
declare var $: any;

@Component({
  selector: "app-page-aprova-pedido",
  templateUrl: "./page-aprova-pedido.component.html",
  styleUrls: ["./page-aprova-pedido.component.css"],
})
export class PageAprovaPedidoComponent {
  SITE: ConfiguracoesSite;
  public API_IMAGENS;

  public hash;
  public pedido;
  public mapProdutos;
  public mapItens;
  private currencyPipe: CurrencyPipe = new CurrencyPipe("pt-BR");
  public apr = false;

  constructor(
    private route: ActivatedRoute,
    private pedidoService: PedidoService
  ) {
    this.SITE = Configuracoes.SITE;
    this.API_IMAGENS = Configuracoes.API_IMAGENS;

    this.route.queryParams.subscribe((params) => {
      console.log("params", params);
      if (params["h"]) {
        this.hash = params["h"];
        this.pedidoService.getPedidoClienteAprovacao(
          this.hash,
          function (resp) {
            console.log(resp);
            this.pedido = resp.pedido;
            this.mapProdutos = resp.mapProdutos;
            this.showPedido();
          }.bind(this)
        );
      }
    });
  }

  aprova() {
    Swal.fire({
      title: "Aprovação",
      icon: "question",
      html: "Tem certeza que deseja aprovar este pedido?",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: '<i class="icofont-check"></i> Aprovar',
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.pedidoService.aprovaPedidoCliente(
          this.hash,
          function (resp) {
            if (resp.pedido) {
              this.apr = true;
              Swal.fire("Pedido", "Aprovado com sucesso!", "success");
            }
          }.bind(this)
        );
      }
    });
  }

  showPedido() {
    if (this.pedido.aprovado) {
      this.apr = true;
    }
    let html = "";
    this.mapItens = {};
    this.pedido.itens.forEach((i) => {
      if (this.mapItens[i.produto]) {
        this.mapItens[i.produto].push(i);
      } else {
        this.mapItens[i.produto] = [i];
      }
    });
    let refs = [];
    for (let key of Object.keys(this.mapProdutos)) {
      refs.push({
        id: this.mapProdutos[key].id,
        ref: this.mapProdutos[key].referencia,
      });
    }
    refs.sort(this.sort1);
    refs.forEach((r) => {
      html += this.table1(this.mapProdutos[r.id], this.mapItens[r.id]);
    });
    $(function () {
      $("#tablePedidoAberto").html(html);
    });
  }

  table1(produto, itens) {
    let mapCor = {};
    itens.forEach((i) => {
      if (mapCor[i.cor]) {
        mapCor[i.cor].push(i);
      } else {
        mapCor[i.cor] = [i];
      }
    });

    let img = '<img src="assets/no-product-image.jpg" class="img-fluid-gcc">';
    if (produto.imagens.length) {
      img = `
      <a href="${this.API_IMAGENS}produtos/${produto.imagens[0].nome}" data-title="${produto.referencia} - ${produto.descricao}" data-toggle="lightbox" data-gallery="gallery-${produto.id}">
        <img src="${this.API_IMAGENS}produtos/${produto.imagens[0].nome}" class="img-fluid-gcc">
      </a>`;
      for (let i = 1; i < produto.imagens.length; i++) {
        img += `<a href="${this.API_IMAGENS}produtos/${produto.imagens[i].nome}" data-title="${produto.referencia} - ${produto.descricao}" data-toggle="lightbox" data-gallery="gallery-${produto.id}"></a>`;
      }
    }
    let head =
      "<tr><td width='95' rowspan='" +
      (Object.keys(mapCor).length + 2) +
      "'>" +
      img +
      "</td><td>COR</td>";
    let rows = [];
    let totalTamanhos = {};
    let totalQtde = 0;
    let totalPreco = 0;
    let totalTotal = 0;
    for (let key of Object.keys(mapCor)) {
      let grade = "<tr><td>" + mapCor[key][0].corDescricao + "</td>";
      let qtde = 0;
      let total = 0;
      produto.tamanhos.forEach((t) => {
        let tamanho = 0;
        mapCor[key].forEach((mC) => {
          if (mC.tamanho == t.tamanho) {
            tamanho = mC.quantidade;
            qtde += mC.quantidade;
            total += mC.total;
            if (totalTamanhos[t.tamanho]) {
              totalTamanhos[t.tamanho] += mC.quantidade;
            } else {
              totalTamanhos[t.tamanho] = mC.quantidade;
            }
          }
        });
        grade += "<td>" + tamanho + "</td>";
      });
      totalQtde += qtde;
      totalPreco += total / qtde;
      totalTotal += total;
      grade += "<td>" + qtde + "</td>";
      grade +=
        "<td>" + this.currencyPipe.transform(total / qtde, "", "") + "</td>";
      grade +=
        "<td>" + this.currencyPipe.transform(total, "", "") + "</td></tr>";
      rows.push(grade);
    }
    let grade = "<tr><td></td>";
    produto.tamanhos.forEach((t) => {
      grade +=
        "<td>" +
        (totalTamanhos[t.tamanho] ? totalTamanhos[t.tamanho] : "") +
        "</td>";
    });
    grade += "<td><b>" + totalQtde + "</b></td>";
    grade +=
      "<td><b>" + this.currencyPipe.transform(totalPreco, "", "") + "</b></td>";
    grade +=
      "<td><b>" +
      this.currencyPipe.transform(totalTotal, "", "") +
      "</b></td></tr>";
    rows.push(grade);
    produto.tamanhos.forEach((t) => {
      head += "<td>" + t.tamanho + "</td>";
    });
    head += "<td>QTDE</td>";
    head += "<td>PREÇO</td>";
    head += "<td>TOTAL</td></tr>";
    return `<div class="table-responsive">
      <table class="table table-hover table-condensed table-striped table-sm table-responsive-xs">
        <thead class="table-head">
            <tr>
                <th colspan="5">${produto.referencia} - ${
      produto.descricao
    }</th>
            </tr>
        </thead>
        <tbody>
            ${head}
            ${rows.join("")}
        </tbody>
      </table>
    </div>`;
  }

  sort1(a, b) {
    if (a.ref.toLowerCase() > b.ref.toLowerCase()) {
      return 1;
    } else if (a.ref.toLowerCase() < b.ref.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  }
}
