import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appMoveOutside]'
})
export class MoveOutsideDirective {
  @Output() moveOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:mouseover', ['$event'])
  public onMove(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      console.log("move-out");
      this.moveOutside.emit();
    }
  }
}
