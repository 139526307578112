import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MaterialMarketingGrupo } from "../../model/material-marketing-grupo";

@Component({
  selector: "app-left-side-bar-menu",
  templateUrl: "./left-side-bar-menu.component.html",
  styleUrls: ["./left-side-bar-menu.component.css"],
})
export class LeftSideBarMenuComponent implements OnInit {
  @Input() menuAtivo;
  @Input() itensMenu: MaterialMarketingGrupo[];
  @Output() selecionaGrupo = new EventEmitter<MaterialMarketingGrupo>();

  constructor() {}

  ngOnInit(): void {}

  public buscaMateriais(grupo: MaterialMarketingGrupo): void {
    if (this.menuAtivo) this.selecionaGrupo.emit(grupo);
  }
}
