import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Configuracoes } from '../entities/configuracoes';
import { Order } from '../entities/order';
import { OrderItem } from '../entities/orderItem';
import { HttpService } from './http-service';
import { InitProvider } from './init-provider';
//import jsPDF from 'jspdf';
//import 'jspdf-autotable';
import { CurrencyPipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
declare var $: any;

@Injectable({
  providedIn: "root",
})
export class PedidoService extends HttpService {
  private versaoController = 18;
  public pedido: Order;
  private observers: ObserverPedido[] = [];
  private observersPedidoRemovido: ObserverPedidoRemovido[] = [];
  private report;
  private reportSavePage = {};
  private currencyPipe: CurrencyPipe = new CurrencyPipe("pt-BR");

  constructor(
    public router: Router,
    public http: Http,
    public initProvider: InitProvider,
    public toastService: ToastrService,
    private _sanitizer: DomSanitizer
  ) {
    super(http, toastService);
  }

  private table2(refs, produtos, itensMap, initY = 0) {
    let head: any = [];
    let columns = [];
    let rows = [];
    let totalQtde = 0;
    let totalTotal = 0;
    let totalPreco = 0;
    let finalY = 0;

    head.push({ content: "REF", styles: { halign: "left", cellWidth: 50 } });
    head.push({
      content: "PRODUTOS",
      styles: { halign: "left", cellWidth: 150 },
    });
    head.push({ content: "QTDE", styles: { halign: "right" } });
    head.push({ content: "IPI", styles: { halign: "right" } });
    head.push({ content: "ICMS SUB", styles: { halign: "right" } });
    head.push({ content: "FCP ST", styles: { halign: "right" } });
    head.push({ content: "PREÇO", styles: { halign: "right" } });
    head.push({ content: "PREÇO + IMP", styles: { halign: "right" } });
    head.push({ content: "TOTAL", styles: { halign: "right" } });

    columns.push({ dataKey: "ref" });
    columns.push({ dataKey: "desc" });
    columns.push({ dataKey: "qtde" });
    columns.push({ dataKey: "ipi" });
    columns.push({ dataKey: "icms_sub" });
    columns.push({ dataKey: "fcp_st" });
    columns.push({ dataKey: "preco" });
    columns.push({ dataKey: "preco_imp" });
    columns.push({ dataKey: "total" });

    refs.forEach((r) => {
      let produto = produtos[r.id];
      let itens = itensMap[r.id];
      if (this.report.previousAutoTable.finalY) {
        finalY = this.report.previousAutoTable.finalY;
      }
      if (initY > finalY) {
        finalY = initY + 10;
      }

      let totalQtdeRef = 0;
      let totalTotalImpRef = 0;
      let totalPrecoImpRef = 0;
      let totalPrecoRef = 0;
      let totalIpi = 0;
      let totalSubs = 0;
      let totalFcpst = 0;
      let icms = 0;
      itens.forEach((mC) => {
        totalQtdeRef += mC.quantidade;
        totalTotalImpRef += mC.total;
        totalPrecoRef += mC.preco;
        totalIpi += mC.imp_valoripi ? mC.imp_valoripi * mC.quantidade : 0;
        totalSubs += mC.imp_valorsubst ? mC.imp_valorsubst * mC.quantidade : 0;
        totalFcpst += mC.imp_valorfcpst ? mC.imp_valorfcpst * mC.quantidade : 0;
        icms = mC.imp_substicms ? mC.imp_substicms : 0;
      });
      totalPrecoImpRef += totalTotalImpRef / totalQtdeRef;
      let grade = { cor: "" };
      grade["ref"] = {
        content: produto.referencia,
        styles: { halign: "left", cellWidth: 50 },
      };
      grade["desc"] = {
        content: produto.descricao,
        styles: { halign: "left", cellWidth: 150 },
      };
      grade["qtde"] = { content: totalQtdeRef, styles: { fontStyle: "bold" } };
      grade["ipi"] = {
        content: this.currencyPipe.transform(totalIpi, "", ""),
        styles: { fontStyle: "bold" },
      };
      grade["icms_sub"] = {
        content: this.currencyPipe.transform(totalSubs, "", ""),
        styles: { fontStyle: "bold" },
      };
      grade["fcp_st"] = {
        content: this.currencyPipe.transform(totalFcpst, "", ""),
        styles: { fontStyle: "bold" },
      };
      grade["preco"] = {
        content: this.currencyPipe.transform(totalPrecoRef, "", ""),
        styles: { fontStyle: "bold" },
      };
      grade["preco_imp"] = {
        content: this.currencyPipe.transform(totalPrecoImpRef, "", ""),
        styles: { fontStyle: "bold" },
      };
      grade["total"] = {
        content: this.currencyPipe.transform(totalTotalImpRef, "", ""),
        styles: { fontStyle: "bold" },
      };
      rows.push(grade);

      totalQtde += totalQtdeRef;
      totalPreco += totalPrecoImpRef;
      totalTotal += totalTotalImpRef;
    });

    rows.push({
      ref: "",
      desc: "",
      qtde: { content: totalQtde, styles: { fontStyle: "bold" } },
      preco: { content: "", styles: { fontStyle: "bold" } },
      ipi: { content: "", styles: { fontStyle: "bold" } },
      icms_sub: { content: "", styles: { fontStyle: "bold" } },
      fcp_st: { content: "", styles: { fontStyle: "bold" } },
      icms: { content: "", styles: { fontStyle: "bold" } },
      total: {
        content: this.currencyPipe.transform(totalTotal, "", ""),
        styles: { fontStyle: "bold" },
      },
    });

    this.report.autoTable({
      startY: finalY + 10,
      pageBreak: "auto",
      theme: "grid",
      headStyles: {
        fillColor: 240,
        textColor: 0,
        fontStyle: "normal",
        halign: "center",
      },
      bodyStyles: { halign: "center", textColor: 0, lineColor: [0, 0, 0] },
      columnStyles: {
        cor: { halign: "left" },
        preco: { halign: "right" },
        total: { halign: "right" },
      },
      head: [
        // [{ content: produto.referencia + ' - ' + produto.descricao, colSpan: produto.tamanhos.length + 4, styles: { halign: 'left', textColor: 0, fillColor: 255 } }],
        head,
      ],
      body: rows,
      columns: columns,
      didDrawPage: function (data) {
        let pageWidth = this.report.internal.pageSize.width;
        let pageHeight = this.report.internal.pageSize.height;
        let numberPages = this.report.internal.getNumberOfPages();
        if (!this.reportSavePage[numberPages]) {
          let str =
            "Página " + numberPages + " de " + "{total_pages_count_string}";
          this.report.text(str, pageWidth - 75, pageHeight - 20);
        }
        this.reportSavePage[numberPages] = true;
      }.bind(this),
      didParseCell: function (data) {
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fillColor = 240;
        }
      },
    });
  }

  private table1(refs, produtos, itensMap, initY = 0) {
    refs.forEach((r) => {
      let produto = produtos[r.id];
      let itens = itensMap[r.id];
      let finalY = 0;
      if (this.report.previousAutoTable.finalY) {
        finalY = this.report.previousAutoTable.finalY;
      }
      if (initY > finalY) {
        finalY = initY + 10;
      }

      let mapCor = {};
      itens.forEach((i) => {
        if (mapCor[i.cor]) {
          mapCor[i.cor].push(i);
        } else {
          mapCor[i.cor] = [i];
        }
      });

      let head: any = ["COR"];
      let columns = [{ dataKey: "cor" }];
      let rows = [];
      let totalTamanhos = {};
      let totalQtde = 0;
      let totalPreco = 0;
      let totalTotal = 0;
      for (let key of Object.keys(mapCor)) {
        let grade = { cor: mapCor[key][0].corDescricao };
        let qtde = 0;
        let total = 0;
        produto.tamanhos.forEach((t) => {
          grade[t.tamanho] = 0;
          mapCor[key].forEach((mC) => {
            if (mC.tamanho == t.tamanho) {
              grade[t.tamanho] = mC.quantidade;
              qtde += mC.quantidade;
              total += mC.total;
              if (totalTamanhos[t.tamanho]) {
                totalTamanhos[t.tamanho] += mC.quantidade;
              } else {
                totalTamanhos[t.tamanho] = mC.quantidade;
              }
            }
          });
        });
        totalQtde += qtde;
        totalPreco += total / qtde;
        totalTotal += total;
        grade["qtde"] = qtde;
        grade["preco"] = this.currencyPipe.transform(total / qtde, "", "");
        grade["total"] = this.currencyPipe.transform(total, "", "");
        rows.push(grade);
      }
      let grade = { cor: "" };
      produto.tamanhos.forEach((t) => {
        grade[t.tamanho] = totalTamanhos[t.tamanho]
          ? totalTamanhos[t.tamanho]
          : "";
      });
      grade["qtde"] = { content: totalQtde, styles: { fontStyle: "bold" } };
      grade["preco"] = {
        content: this.currencyPipe.transform(totalPreco, "", ""),
        styles: { fontStyle: "bold" },
      };
      grade["total"] = {
        content: this.currencyPipe.transform(totalTotal, "", ""),
        styles: { fontStyle: "bold" },
      };
      rows.push(grade);

      produto.tamanhos.forEach((t) => {
        head.push(t.tamanho);
        columns.push({ dataKey: t.tamanho });
      });
      head.push("QTDE");
      head.push({ content: "PREÇO", styles: { halign: "right" } });
      head.push({ content: "TOTAL", styles: { halign: "right" } });
      columns.push({ dataKey: "qtde" });
      columns.push({ dataKey: "preco" });
      columns.push({ dataKey: "total" });

      //console.log('-------------------');
      //console.log('mapCor', mapCor);
      //console.log('head', head);
      //console.log('columns', columns);
      //console.log('rows1', rows1);

      this.report.autoTable({
        startY: finalY + 10,
        pageBreak: "auto",
        theme: "plain",
        headStyles: {
          fillColor: 240,
          textColor: 0,
          fontStyle: "normal",
          halign: "center",
        },
        bodyStyles: { halign: "center", textColor: 0 },
        columnStyles: {
          cor: { halign: "left" },
          preco: { halign: "right" },
          total: { halign: "right" },
        },
        head: [
          [
            {
              content: produto.referencia + " - " + produto.descricao,
              colSpan: produto.tamanhos.length + 4,
              styles: { halign: "left", textColor: 0, fillColor: 255 },
            },
          ],
          head,
        ],
        body: rows,
        columns: columns,
        didDrawPage: function (data) {
          let pageWidth = this.report.internal.pageSize.width;
          let pageHeight = this.report.internal.pageSize.height;
          let numberPages = this.report.internal.getNumberOfPages();
          if (!this.reportSavePage[numberPages]) {
            let str =
              "Página " + numberPages + " de " + "{total_pages_count_string}";
            this.report.text(str, pageWidth - 75, pageHeight - 20);
          }
          this.reportSavePage[numberPages] = true;
        }.bind(this),
        didParseCell: function (data) {
          if (data.row.index === rows.length - 1) {
            data.cell.styles.fillColor = 240;
          }
        },
      });
    });
  }

  public pedidoPDF(pedido: any, produtos, itens, type) {
    console.log(pedido);

    let cliente = pedido.cliente;
    //this.report = new jsPDF({unit: "px", putOnlyUsedFonts: true });
    //window.jspdf.jsPDF
    this.report = new window["jspdf"].jsPDF({
      unit: "px",
      putOnlyUsedFonts: true,
    });
    let title = Configuracoes.SITE.title;
    //let title = "Pedido " + pedido.id + " - " + Configuracoes.SITE.title;
    let pageWidth = this.report.internal.pageSize.width;
    let pageHeight = this.report.internal.pageSize.height;
    this.report.setProperties({ title: "Pedido" + pedido.id });
    this.report.setFontSize(9.2);
    this.report.text(
      title,
      pageWidth / 2 - this.report.getTextWidth(title) / 2,
      30
    );
    if (Configuracoes.SITE.infoCabecalhoReport) {
      let cabecalho = Configuracoes.SITE.infoCabecalhoReport.split("<br>");
      let infoCabecalho1 = cabecalho[0] ? cabecalho[0] : "";
      let infoCabecalho2 = cabecalho[1] ? cabecalho[1] : "";
      this.report.text(
        infoCabecalho1,
        pageWidth / 2 - this.report.getTextWidth(infoCabecalho1) / 2,
        40
      );
      this.report.text(
        infoCabecalho2,
        pageWidth / 2 - this.report.getTextWidth(infoCabecalho2) / 2,
        48
      );
    }
    if (pedido.tipo.precoDivergente && pedido.precoDivergenteStatus == 2) {
      let infoAprov = "*** não válido como orçamento, preço divergente ***";
      //this.report.setFontType("bold");
      this.report.setFont("Helvetica", "bold");
      this.report.text(
        infoAprov,
        pageWidth / 2 - this.report.getTextWidth(infoAprov) / 2,
        58
      );
      this.report.setFont("Helvetica", "normal");
      //this.report.setFont("arial", "normal", "normal");
      //this.report.setFontType("normal");
    }
    /*let pedidoIdErp = "";
        let pedidoCodigoErp = "";
        if(pedido.tipo.tipo == "COMPRA"){
            pedidoIdErp = (pedido.idErpCompra ? pedido.idErpCompra.toString() : "");
            pedidoCodigoErp = (pedido.codigoErpCompra ? pedido.codigoErpCompra.toString() : "");
        }
        else if(pedido.tipo.tipo == "VENDA"){
            pedidoIdErp = (pedido.idErpVenda ? pedido.idErpVenda.toString() : "");
            pedidoCodigoErp = (pedido.codigoErpVenda ? pedido.codigoErpVenda.toString() : "");
        }
        else if(pedido.tipo.tipo == "TRANSFERENCIA"){
            pedidoIdErp = (pedido.idErpTransferencia ? pedido.idErpTransferencia.toString() : "");
            pedidoCodigoErp = (pedido.codigoErpTransferencia ? pedido.codigoErpTransferencia.toString() : "");
        }
        if(pedidoIdErp || pedidoCodigoErp){
            this.report.setFontType("bold");
            this.report.text("Código", 321, 30);
            this.report.setFontType("normal");
        }
        this.report.text(pedidoIdErp, 370, 30);
        this.report.text(pedidoCodigoErp, 370, 40);*/
    let pedidoCodigoErp = "";
    if (pedido.tipo.tipo == "COMPRA") {
      pedidoCodigoErp = pedido.codigoErpCompra
        ? pedido.codigoErpCompra.toString()
        : "";
    } else if (pedido.tipo.tipo == "VENDA") {
      pedidoCodigoErp = pedido.codigoErpVenda
        ? pedido.codigoErpVenda.toString()
        : "";
    } else if (pedido.tipo.tipo == "TRANSFERENCIA") {
      pedidoCodigoErp = pedido.codigoErpTransferencia
        ? pedido.codigoErpTransferencia.toString()
        : "";
    }
    //this.report.setFontType("bold");
    this.report.setFont("Helvetica", "bold");
    this.report.text("Código", 321, 30);
    this.report.setFont("Helvetica", "normal");
    //this.report.setFontType("normal");
    this.report.text(pedido.id + "", 370, 30);
    this.report.text(pedidoCodigoErp + "", 370, 40);

    let status = "ABERTO";
    if (
      pedido.infos &&
      pedido.infos.length &&
      pedido.infos[pedido.infos.length - 1].statusDescricao
    ) {
      status = pedido.infos[pedido.infos.length - 1].statusDescricao;
    }
    let logo = $("#gcc-logo-agilb2b");
    let logoWidth = logo.width();
    let logoHeight = logo.height();
    let logoImg = new Image();
    logoImg.src = "images/logo.png";
    this.report.addImage(
      logoImg,
      "PNG",
      20,
      20,
      logoWidth - logoWidth * 0.3,
      logoHeight - logoHeight * 0.3
    );
    this.report.autoTableSetDefaults({
      styles: { cellPadding: 1, fontSize: 9.2 },
      margin: { right: 16, left: 16 },
    });
    let clienteStr =
      cliente[Configuracoes.SITE.identificacaoCliente] + " - " + cliente.nome;
    if (cliente.documento) {
      clienteStr += ", " + cliente.documento;
    }
    //{ td1: { content: cliente.tipoDocumento, styles: { fontStyle: 'bold' } }, td2: cliente.documento }
    let body1 = [
      {
        td1: { content: "Cliente", styles: { fontStyle: "bold" } },
        td2: clienteStr,
      },
    ];
    if (cliente.contato) {
      body1.push({
        td1: { content: "Contato", styles: { fontStyle: "bold" } },
        td2: cliente.contato,
      });
    }
    if (cliente.telefone) {
      body1.push({
        td1: { content: "Telefone", styles: { fontStyle: "bold" } },
        td2: cliente.telefone,
      });
    }
    if (cliente.email) {
      body1.push({
        td1: { content: "Email", styles: { fontStyle: "bold" } },
        td2: cliente.email,
      });
    }
    if (cliente.endereco) {
      let endereco = "";
      let numero = "";
      let bairro = "";
      let cidade = "";
      let uf = "";
      let cep = "";
      if (cliente.idEnderecoErp == pedido.idEnderecoErp) {
        endereco = cliente.endereco;
        numero = cliente.numero;
        bairro = cliente.bairro;
        cidade = cliente.cidade.nome;
        uf = cliente.cidade.uf;
        cep = cliente.cep;
      } else {
        for (let i = 0; cliente.enderecos.length; i++) {
          if (cliente.enderecos[i].idEnderecoErp == pedido.idEnderecoErp) {
            endereco = cliente.enderecos[i].endereco;
            numero = cliente.enderecos[i].numero;
            bairro = cliente.enderecos[i].bairro;
            cidade = cliente.enderecos[i].cidade.nome;
            uf = cliente.enderecos[i].cidade.uf;
            cep = cliente.enderecos[i].cep;
            break;
          }
        }
      }
      let endStr = endereco;
      if (numero) {
        endStr += ", " + numero;
      }
      if (bairro) {
        endStr += " - " + bairro;
      }
      if (cidade && uf) {
        endStr += ", " + cidade + " - " + uf;
      }
      if (cep) {
        endStr += ", " + cep;
      }

      body1.push({
        td1: { content: "Endereço", styles: { fontStyle: "bold" } },
        td2: endStr,
      });
    }
    if (pedido.usuario) {
      body1.push({
        td1: { content: "Usuário", styles: { fontStyle: "bold" } },
        td2: pedido.usuario.nome,
      });
    }
    if (pedido.formaPagamento) {
      body1.push({
        td1: { content: "Forma Pgto", styles: { fontStyle: "bold" } },
        td2:
          pedido.formaPagamento.codigo +
          " - " +
          pedido.formaPagamento.descricao,
      });
    }
    body1.push({
      td1: { content: "Tipo", styles: { fontStyle: "bold" } },
      td2: pedido.tipo.tipo + " - " + pedido.tipo.descricao,
    });
    body1.push({
      td1: { content: "Observações", styles: { fontStyle: "bold" } },
      td2: pedido.observacao ? pedido.observacao : "...",
    });
    this.report.autoTable({
      startY: 63,
      theme: "plain",
      showHead: "never",
      margin: { left: 16, right: 130 },
      bodyStyles: { textColor: 0 },
      columnStyles: { td1: { cellWidth: 50 } },
      body: body1,
      columns: [{ dataKey: "td1" }, { dataKey: "td2" }],
    });
    let finalY = this.report.previousAutoTable.finalY;
    let dataPedido = pedido.data ? pedido.data : pedido.dataAberto;
    let bodyRight = [
      { td1: { content: "Data", styles: { fontStyle: "bold" } } },
      { td1: dataPedido },
    ];
    if (pedido.dataEntrega) {
      bodyRight.push({
        td1: { content: "Data Entrega", styles: { fontStyle: "bold" } },
      });
      bodyRight.push({ td1: pedido.dataEntrega });
    }
    if (pedido.freteDescr) {
      let freteDescr = pedido.freteDescr;
      if (pedido.freteCepDestino) {
        freteDescr += " (" + pedido.freteCepDestino + ")";
      }
      bodyRight.push({
        td1: { content: "Frete", styles: { fontStyle: "bold" } },
      });
      bodyRight.push({ td1: freteDescr });
    }
    bodyRight.push({
      td1: { content: "Status", styles: { fontStyle: "bold" } },
    });
    bodyRight.push({ td1: status });
    this.report.autoTable({
      startY: 63,
      theme: "plain",
      showHead: "never",
      margin: { right: 16, left: 320 },
      bodyStyles: { textColor: 0 },
      body: bodyRight,
      columns: [{ dataKey: "td1" }],
    });

    //console.log('produtos', produtos);
    //console.log('itens', itens);
    let countReferencias = 0;
    let refs = [];
    for (let key of Object.keys(produtos)) {
      refs.push({ id: produtos[key].id, ref: produtos[key].referencia });
    }
    refs.sort(this.sort1);
    refs.forEach((r) => {
      countReferencias++;
    });

    if (Configuracoes.SITE.mostraImpressaoGradeDetalhada)
      this.table1(refs, produtos, itens, finalY);
    else this.table2(refs, produtos, itens, finalY);

    //for(let key of Object.keys(produtos)){
    //this.table1(produtos[key], itens[key], finalY);
    //countReferencias++;
    //}
    let newFinalY = this.report.previousAutoTable.finalY;
    //this.report.addPage();
    this.report.autoTable({
      startY: newFinalY + 30,
      styles: { fontSize: 10 },
      theme: "plain",
      showHead: "never",
      margin: { left: 16, right: 240 },
      bodyStyles: { textColor: 0 },
      body: [
        { td1: { content: "Resumo", styles: { fontStyle: "bold" } }, td2: "" },
        { td1: "Quantidade Itens", td2: pedido.quantidade },
        { td1: "Referências", td2: countReferencias },
      ],
      columns: [{ dataKey: "td1" }, { dataKey: "td2" }],
    });
    let body2 = [
      { td1: "", td2: "" },
      {
        td1: {
          content: "Produtos  ",
          styles: { fontStyle: "bold", halign: "right" },
        },
        td2: this.currencyPipe.transform(pedido.subtotal, "", ""),
      },
      {
        td1: {
          content: "Impostos  ",
          styles: { fontStyle: "bold", halign: "right" },
        },
        td2: this.currencyPipe.transform(pedido.impostos, "", ""),
      },
      {
        td1: {
          content: "Acréscimos  ",
          styles: { fontStyle: "bold", halign: "right" },
        },
        td2: this.currencyPipe.transform(pedido.acrescimo, "", ""),
      },
      {
        td1: {
          content: "Descontos  ",
          styles: { fontStyle: "bold", halign: "right" },
        },
        td2: this.currencyPipe.transform(pedido.desconto, "", ""),
      },
    ];
    if (pedido.freteValor) {
      body2.push({
        td1: {
          content: "Frete  ",
          styles: { fontStyle: "bold", halign: "right" },
        },
        td2: this.currencyPipe.transform(pedido.freteValor, "", ""),
      });
    }
    body2.push({
      td1: {
        content: "Valor Total  ",
        styles: { fontStyle: "bold", halign: "right" },
      },
      td2: this.currencyPipe.transform(pedido.total, "", ""),
    });
    this.report.autoTable({
      startY: newFinalY + 10,
      styles: { fontSize: 10 },
      theme: "plain",
      showHead: "never",
      margin: { right: 16, left: 240 },
      bodyStyles: { textColor: 0 },
      body: body2,
      columns: [{ dataKey: "td1" }, { dataKey: "td2" }],
    });
    let numberPages = this.report.internal.getNumberOfPages();
    let str = "Página " + numberPages + " de " + "{total_pages_count_string}";
    this.report.text(str, pageWidth - 75, pageHeight - 20);
    this.report.setFontSize(8);
    this.report.setTextColor(210);
    let geradoPor = "Gerado por ÁgilB2B";
    this.report.text(
      geradoPor,
      pageWidth / 2 - this.report.getTextWidth(geradoPor) / 2,
      pageHeight - 10
    );
    this.report.putTotalPages("{total_pages_count_string}");
    if (type == "data") {
      return this.report.output("datauristring"); //data
    } else if (type == "bloburi") {
      return this.report.output("bloburi");
    } else if (type == "download") {
      this.report.output("save", "pedido.pdf");
    }
  }

  private pedidoNovo(tipo, filial, tabela, pedidoClientes): Order {
    let pedido: Order = {
      id: 0,
      owner: this.initProvider.usuario.id,
      tipo: tipo,
      filial: filial,
      tabela: tabela,
      observacao: "",
      status: "ABERTO",
      itens: [],
      clientes: pedidoClientes,
      quantidade: 0,
      subtotal: 0,
      impostos: 0,
      acrescimo: 0,
      desconto: 0,
      total: 0,
      versao: 1,
    };
    return pedido;
  }

  aprovaPedidoCliente(hash, callback) {
    this.post("principal/aprovaPedidoCliente", { hash: hash }, callback);
  }

  getPedidoClienteAprovacao(hash, callback) {
    this.post("principal/getPedidoClienteAprovacao", { hash: hash }, callback);
  }

  getAprovaPedidoLink(idPedidoCliente, callback) {
    this.get("pedido/getAprovaPedidoLink/" + idPedidoCliente, callback);
  }

  getClienteAprovadoDescr(clients: []) {
    let aprovadoAll = true;
    let rtn =
      '<span style="color:orange; font-size:0.8rem; font-weight:bold; text-decoration:underline;">AGUARDANDO</span>';
    clients.forEach((c) => {
      if (c["aprovado"]) {
        rtn =
          '<span style="color:blue; font-size:0.8rem; font-weight:bold; text-decoration:underline;">PARCIAL</span>';
      } else {
        aprovadoAll = false;
      }
    });
    if (aprovadoAll) {
      rtn =
        '<span style="color:green; font-size:0.8rem; font-weight:bold; text-decoration:underline;">APROVADO</span>';
    }
    return this._sanitizer.bypassSecurityTrustHtml(rtn);
  }

  pedidoImpressao(hash, callback) {
    this.post(
      "principal/pedidoImpressao",
      { hash: hash },
      callback,
      function (error) { }
    );
  }

  abrir(form, callback, callbackError) {
    let pedido: Order = {
      id: 0,
      owner: this.initProvider.usuario.id,
      tipo: form.tipo,
      filial: form.filial,
      tabela: form.tabela,
      dataEntrega: form.dataEntrega,
      observacao: "",
      status: "ABERTO",
      itens: [],
      clientes: form.clientes,
      quantidade: 0,
      subtotal: 0,
      impostos: 0,
      acrescimo: 0,
      desconto: 0,
      total: 0,
      versao: 1,
      versaoController: this.versaoController,
    };
    this.post("pedido/salvar", pedido, callback, callbackError);
  }

  listaPedido(form, callback, salvoCheckout) {
    if (salvoCheckout) {
      form["salvoCheckout"] = salvoCheckout;
    }
    this.post("pedido/listaPedido", form, callback);
  }

  listaPedidoCliente(form, callback, callbackError) {
    this.post("pedido/listaPedidoCliente", form, callback, callbackError);
  }

  deletePedidoAberto(pedido: Order, callback, callbackError) {
    this.delete("pedido/remove/" + pedido.id, callback, callbackError);
  }

  refresh() {
    for (let i = 0; i < this.observers.length; i++) {
      this.observers[i].notify(this.pedido);
    }
  }

  removed() {
    for (let i = 0; i < this.observersPedidoRemovido.length; i++) {
      this.observersPedidoRemovido[i].notify(this.pedido);
    }
  }

  confirmaTrocaPedido(p: Order) {
    if (!this.existePedidoEmAberto())
      return true;
    else if (this.pedido.id == p.id)
      return true;
    else
      return confirm("Seu pedido ainda não foi concluído! Lembrando que seu pedido continuará no status Em Digitação para futuras edições e conclusão.");
  }

  confirmaAbandonarPedidoEmAberto() {
    if (!this.existePedidoEmAberto())
      return true;
    else
      return confirm("Seu pedido ainda não foi concluído! Lembrando que seu pedido continuará no status Em Digitação para futuras edições e conclusão.");
  }

  existePedidoEmAberto() {
    return this.pedido != null;
  }

  select(p: Order) {
    console.log("select", p);
    this.pedido = p;
    for (let i = 0; i < this.observers.length; i++) {
      this.observers[i].notify(p);
    }
  }

  unSelectAndDelete(pedido: Order, callback, callbackError) {
    console.log("unSelectAndDelete", pedido);
    this.deletePedidoAberto(
      pedido,
      function () {
        this.pedido = null;
        for (let i = 0; i < this.observers.length; i++) {
          this.observers[i].notify(null);
        }
        callback();
      }.bind(this),
      callbackError
    );
  }

  unSelectAndDeleteCheckout() {
    this.pedido = null;
    for (let i = 0; i < this.observers.length; i++) {
      this.observers[i].notify(null);
    }
  }

  copy(pedido: Order, callback) {
    console.log("copy", pedido);
    this.get(
      "pedido/clone/" + pedido.id,
      function () {
        callback();
      }.bind(this)
    );
  }

  recalculaPedido() {
    this.pedido.quantidade = 0;
    this.pedido.subtotal = 0;
    this.pedido.impostos = 0;
    this.pedido.acrescimo = 0;
    this.pedido.desconto = 0;
    this.pedido.total = 0;
    this.pedido.clientes.forEach((pc) => {
      pc.quantidade = 0;
      pc.subtotal = 0;
      pc.impostos = 0;
      pc.acrescimo = 0;
      pc.acrescimoPercent = 0;
      pc.desconto = 0;
      pc.descontoPercent = 0;
      pc.total = 0;
    });
    this.pedido.itens.forEach((i) => {
      this.pedido.quantidade += i.quantidade;
      this.pedido.subtotal += i.quantidade * i.preco;
      this.pedido.impostos += i.quantidade * i.imp_valorimposto;
      this.pedido.acrescimo += i.acrescimo * i.quantidade;
      this.pedido.desconto += i.desconto * i.quantidade;
      this.pedido.total += i.total;
      this.pedido.clientes.forEach((pc) => {
        if (i.cliente == pc.cliente.id) {
          pc.quantidade += i.quantidade;
          pc.subtotal += i.quantidade * i.preco;
          pc.impostos += i.quantidade * i.imp_valorimposto;
          pc.acrescimo += i.acrescimo * i.quantidade;
          pc.acrescimoPercent = i.acrescimoPercent;
          pc.desconto += i.desconto * i.quantidade;
          pc.descontoPercent = i.descontoPercent;
          pc.total += i.total;
        }
      });
    });
    console.log("recalculaPedido", this.pedido);
  }

  setCartItems(produto, items, callback, callbackError) {
    this.showLoading();
    let oldItens: any = [];
    this.pedido.itens.forEach((i) => {
      if (i["produto"] != produto) oldItens.push(i);
    });
    this.pedido.itens = oldItens;
    items.forEach((i) => {
      this.pedido.itens.push(i as never);
    });
    this.recalculaPedido();
    let pedidoInt = Object.assign({}, this.pedido);
    pedidoInt.clientes = [];
    this.pedido.clientes.forEach((pc) => {
      let newPC = Object.assign({}, pc);
      newPC.cliente = pc.cliente.id;
      pedidoInt.clientes.push(newPC);
    });
    delete pedidoInt["formaPagamento"];
    pedidoInt["tipo"] = this.pedido.tipo.id;
    pedidoInt["filial"] = this.pedido.filial.id;
    pedidoInt["tabela"] = this.pedido.tabela.id;
    pedidoInt["produtoIdEdit"] = produto;
    pedidoInt["versaoController"] = this.versaoController;
    this.post("pedido/salvar", pedidoInt, callback, callbackError);
  }

  setCartItemsSku(items: OrderItem[], callback, callbackError) {
    const body = {
      pedido: this.pedido.id,
      items
    }
    this.post("pedido/salvarSKU", body, callback, callbackError);
  }

  setCartItem(item: OrderItem, callback, callbackError) {
    this.showLoading();
    let oldItens: any = [];
    this.pedido.itens.forEach((i) => {
      if (
        i["produto"] == item.produto &&
        i["cor"] == item.cor &&
        i["estampa"] == item.estampa &&
        i["tamanho"] == item.tamanho &&
        i["cliente"] == item.cliente
      ) {
        //
      } else {
        oldItens.push(i);
      }
    });
    if (item.quantidade > 0) {
      oldItens.push(item);
    }
    this.pedido.itens = oldItens;
    //this.pedido.itens.push(item as never);
    /*items.forEach(i => {
            this.pedido.itens.push(i as never);
        });*/
    this.recalculaPedido();
    let pedidoInt = Object.assign({}, this.pedido);
    pedidoInt.clientes = [];
    this.pedido.clientes.forEach((pc) => {
      let newPC = Object.assign({}, pc);
      newPC.cliente = pc.cliente.id;
      pedidoInt.clientes.push(newPC);
    });
    delete pedidoInt["formaPagamento"];
    pedidoInt["tipo"] = this.pedido.tipo.id;
    pedidoInt["filial"] = this.pedido.filial.id;
    pedidoInt["tabela"] = this.pedido.tabela.id;
    pedidoInt["produtoIdEdit"] = item.produto;
    pedidoInt["versaoController"] = this.versaoController;
    this.post("pedido/salvar", pedidoInt, callback, callbackError);
  }

  removeCartItem(produto, callback) {
    /*let oldItens:any = [];
        this.pedido.itens.forEach(i => {
            if(i['produto'] != produto)
            oldItens.push(i);
        });
        this.pedido.itens = oldItens;
        this.recalculaPedido();
        let pedidoInt = Object.assign({}, this.pedido);
        pedidoInt.clientes = [];
        this.pedido.clientes.forEach(pc => {
            let newPC = Object.assign({}, pc);
            newPC.cliente = pc.cliente.id;
            pedidoInt.clientes.push(newPC);
        });
        pedidoInt['tipo'] = this.pedido.tipo.id;
        pedidoInt['filial'] = this.pedido.filial.id;
        pedidoInt['tabela'] = this.pedido.tabela.id;
        pedidoInt['versaoController'] = this.versaoController;*/
    this.delete(
      "pedido/removeItens/" + this.pedido.id + "/" + produto,
      function (resp) {
        callback(resp);
      }.bind(this)
    );
    this.dimissLoading();
  }

  editSalvaCheckout(pedidoId, callback) {
    this.get(
      "pedido/editSalvaCheckout/" + pedidoId,
      function (resp) {
        callback(resp);
      }.bind(this)
    );
  }

  editFilial(idFilial, callback) {
    this.get(
      "pedido/editFilial/" + this.pedido.id + "/" + idFilial,
      function (resp) {
        callback(resp);
      }.bind(this)
    );
  }

  editTipo(idTipo, callback) {
    this.get(
      "pedido/editTipo/" + this.pedido.id + "/" + idTipo,
      function (resp) {
        callback(resp);
      }.bind(this)
    );
  }

  finalizaPedido(files, form, itens, callback, callbackError) {
    if (!this.pedido) return;

    this.pedido.itens = itens;
    this.recalculaPedido();
    let pedidoInt = Object.assign({}, this.pedido);
    if (form.files) {
      pedidoInt["files"] = form.files;
    }
    pedidoInt.clientes = [];
    pedidoInt.itens = itens;
    pedidoInt.observacao = form.observacao;
    pedidoInt.observacaoNota = form.observacaoNota;
    pedidoInt.formaPagamento = parseInt(form.formaPagamento);
    this.pedido.clientes.forEach((pc) => {
      let newPC = Object.assign({}, pc);
      newPC.cliente = pc.cliente.id;
      pedidoInt.clientes.push(newPC);
    });
    pedidoInt["tipo"] = this.pedido.tipo.id;
    pedidoInt["filial"] = this.pedido.filial.id;
    pedidoInt["tabela"] = this.pedido.tabela.id;

    pedidoInt["tipoFrete"] = form.tipoFrete;
    pedidoInt["freteId"] = form.freteId;
    pedidoInt["freteOferta"] = form.freteOferta;
    pedidoInt["freteToken"] = form.freteToken;
    pedidoInt["freteValor"] = form.freteValor;
    pedidoInt["freteCepDestino"] = form.freteCepDestino;
    pedidoInt["freteDescr"] = form.freteDescr;
    pedidoInt["freteNome"] = form.freteNome;
    pedidoInt["freteSobrenome"] = form.freteSobrenome;
    pedidoInt["freteModalidade"] = form.freteModalidade;

    pedidoInt["versaoController"] = this.versaoController;

    if (files.length > 0) {
      let formData: FormData = new FormData();
      for (let f of files) {
        formData.append("files", f);
      }
      formData.append(
        "pedidoForm",
        new Blob([JSON.stringify(pedidoInt)], {
          type: "application/json",
        })
      );
      this.postFile(
        "pedido/finalizaComUpload",
        formData,
        callback,
        callbackError
      );
    } else {
      this.post("pedido/finaliza", pedidoInt, callback, callbackError);
    }
  }

  salvaCheckout(form, itens, callback, callbackError) {
    if (!this.pedido) return;

    this.pedido.itens = itens;
    this.recalculaPedido();
    let pedidoInt = Object.assign({}, this.pedido);
    pedidoInt.clientes = [];
    pedidoInt.itens = itens;
    pedidoInt.observacao = form.observacao;
    pedidoInt.observacaoNota = form.observacaoNota;
    pedidoInt.formaPagamento = parseInt(form.formaPagamento);
    this.pedido.clientes.forEach((pc) => {
      let newPC = Object.assign({}, pc);
      newPC.cliente = pc.cliente.id;
      pedidoInt.clientes.push(newPC);
    });
    pedidoInt["tipo"] = this.pedido.tipo.id;
    pedidoInt["filial"] = this.pedido.filial.id;
    pedidoInt["tabela"] = this.pedido.tabela.id;

    pedidoInt["tipoFrete"] = form.tipoFrete;
    pedidoInt["freteId"] = form.freteId;
    pedidoInt["freteValor"] = form.freteValor;
    pedidoInt["freteCepDestino"] = form.freteCepDestino;
    pedidoInt["freteDescr"] = form.freteDescr;
    pedidoInt["freteNome"] = form.freteNome;
    pedidoInt["freteSobrenome"] = form.freteSobrenome;
    pedidoInt["freteModalidade"] = form.freteModalidade;

    pedidoInt["versaoController"] = this.versaoController;

    this.post("pedido/salvaCheckout", pedidoInt, callback, callbackError);
  }

  mapaPedidoItens(itens: OrderItem[], mapClientes) {
    itens.sort(this.sort2);
    let quantSum = 0;
    let precoSum = 0;
    let arr = [];
    itens.forEach((i) => {
      if (mapClientes[i.cliente]) {
        arr.push(i);
        quantSum += i.quantidade;
        precoSum += i.total;
      }
    });
    return { itens: arr, quant: quantSum, preco: precoSum };
  }

  mapaPedidoItensPorProduto(itens: OrderItem[], mapClientes) {
    //console.log(itens);
    itens.sort(this.sort2);
    let quantSum = 0;
    let precoSum = 0;
    let arr = [];
    let map = {};
    itens.forEach((i) => {
      if (mapClientes[i.cliente]) {
        let total = i.quantidade * i.preco;
        i.total = total;
        if (!map[i.produto]) {
          map[i.produto] = {
            cor: i.cor,
            corDescricao: i.corDescricao,
            estampa: i.estampa,
            tamanho: i.tamanho,
            cliente: 0,
            produto: i.produto,
            produtoReferencia: i.produtoReferencia,
            produtoDescricao: i.produtoDescricao,
            imagemProduto: i.imagemProduto,
            quantidade: i.quantidade,
            preco: i.preco,
            total: total,
          };
        } else {
          map[i.produto].quantidade += i.quantidade;
          map[i.produto].total += i.total;
        }
      }
    });

    let refs = [];
    for (let key of Object.keys(map)) {
      refs.push({ id: key, ref: map[key].produtoReferencia });
    }
    refs.sort(this.sort1);
    refs.forEach((r) => {
      arr.push(map[r.id]);
      quantSum += map[r.id].quantidade;
      precoSum += map[r.id].total;
    });
    //for(let key of Object.keys(map)){
    //arr.push(map[key]);
    //quantSum += map[key].quantidade;
    //precoSum += map[key].total;
    //}
    //console.log(map);
    //console.log(arr);
    return { itens: arr, quant: quantSum, preco: precoSum };
  }

  codigoBarras(pedidoId, form, callback, callbackError) {
    this.post("pedido/codigoBarras/" + pedidoId, form, callback, callbackError);
  }

  codigoBarrasDel(pedidoId, pedidoItemId, callback) {
    this.post(
      "pedido/codigoBarrasDel/" + pedidoId,
      { pedidoItemId: pedidoItemId },
      callback
    );
  }

  getStatusERP(form, callback) {
    this.post("pedido/getStatusERP", form, callback);
  }

  getFormasPagamento(pedidoId, clientesId, callback) {
    this.post("pedido/getFormasPagamento/" + pedidoId, clientesId, callback);
  }

  getPedidoCliente(idPC, callback) {
    this.get("pedido/pedidoCliente/" + idPC, callback);
  }

  /*getPedidoPdf(pedidoId, callback) {
        this.get('')
    }*/

  registerObserver(observer: ObserverPedido) {
    this.observers.push(observer);
  }

  removeObserver(observer: ObserverPedido) {
    const index = this.observers.indexOf(observer);
    const removed = this.observers.splice(index, 1);
  }

  registerObserverToRemovedOrders(observer: ObserverPedidoRemovido) {
    this.observersPedidoRemovido.push(observer);
  }

  removeObserverToRemovedOrders(observer: ObserverPedidoRemovido) {
    const index = this.observersPedidoRemovido.indexOf(observer);
    const removed = this.observersPedidoRemovido.splice(index, 1);
  }

  sort1(a, b) {
    if (a.ref.toLowerCase() > b.ref.toLowerCase()) {
      return 1;
    } else if (a.ref.toLowerCase() < b.ref.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  }

  sort2(a, b) {
    if (a.produtoReferencia.toLowerCase() > b.produtoReferencia.toLowerCase()) {
      return 1;
    } else if (
      a.produtoReferencia.toLowerCase() < b.produtoReferencia.toLowerCase()
    ) {
      return -1;
    } else {
      return 0;
    }
  }
}

export interface ObserverPedido {
  notify(pedido: Order);
}

export interface ObserverPedidoRemovido {
  notify(pedido: Order)
}