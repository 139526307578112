import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Configuracoes } from 'src/app/entities/configuracoes';
import { PedidoService } from 'src/app/services/pedido-service';

@Component({
  selector: 'app-product-quantity-input',
  templateUrl: './product-quantity-input.component.html',
  styleUrls: ['./product-quantity-input.component.css']
})
export class ProductQuantityInputComponent implements OnInit {

  @Output()
  public onAdd = new EventEmitter<number>();
  @Input()
  public product: any;
  @Input()
  public produtoDescrHeight;
  public pedido: any;
  public quantity: number = 0;
  public inputClass: string = ''
  public SITE: any;
  public theme: string;
  public hovered: boolean = false;

  constructor(
    public pedidoService: PedidoService,
    public toastService: ToastrService
  ) {
    this.pedido = this.pedidoService.pedido;
    this.SITE = Configuracoes.SITE;
    this.theme = Configuracoes.themes.filter((i) => i.id == Configuracoes.SITE.theme)[0];
  }

  ngOnInit(): void {
    if (this.product.quantidade) {
      this.quantity = this.product.quantidade;
    } else {
      this.quantity = 1;
      this.inputClass = 'disabled';
    }
  }

  public addCart() {
    if (this.product.estoque >= this.quantity) {
      this.onAdd.emit(this.quantity);
    } else {
      this.toastService.warning("Estoque insuficiente!", "Produto", {
        positionClass: "toast-bottom-left",
      });
    }
  }

  public setQuantity(event) {
    this.quantity = Number(event.target.value);
    this.inputClass = '';
  }

  public plus() {
    this.quantity = this.quantity + 1;
    this.inputClass = '';
  }

  public less() {
    this.quantity = this.quantity > 0 ? this.quantity - 1 : this.quantity;
    this.inputClass = '';
  }

}