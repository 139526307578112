import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { ProductService } from "src/app/services/product-service";
import { ActivatedRoute, Router } from "@angular/router";
import { Configuracoes } from "src/app/entities/configuracoes";
import { ObserverPedido, ObserverPedidoRemovido, PedidoService } from "src/app/services/pedido-service";
import { Order } from "src/app/entities/order";
import { ConfiguracoesSite } from "src/app/entities/configuracoesSite";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { OrderItem } from "src/app/entities/orderItem";
import { CurrencyPipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { SecurityService } from "src/app/services/security-service";
import { CartService } from "src/app/services/cart-service";
import { ProductsMenu } from "src/app/entities/productMenu";
import { CatalogService } from "src/app/services/catalog.service";
import { SessionService } from "src/app/services/session.service";
import { Observable } from "rxjs";
declare var $: any;

@Component({
  selector: "app-page-catalog",
  templateUrl: "./page-catalog.component.html",
  styleUrls: ["./page-catalog.component.css"],
})
export class PageCatalogComponent implements OnInit, OnDestroy, ObserverPedidoRemovido {
  SITE: ConfiguracoesSite;

  public page: number = 0;
  public totalPages: number;
  public totalElements: number;
  public pageSize: number = 32;
  //public ordem:string = 'descricao';
  //public direcao:string = 'ASC';
  public products = [];
  public clientes: [];
  //public filtro = {};
  //public filtroDescr = '';
  public filtros;
  //public busca:string;
  public toScroll: boolean = true;
  //public selectedOrder = 0;
  public rows: any[] = [];
  public objectKeys = Object.keys;
  public API_IMAGENS: String;
  public menuDinamico: boolean;
  public pedido: Order;
  public tabelaPadrao;
  public shareLink;

  //visualizacaoModo 4x4
  public produtoColXL = 3;
  public produtoDescrLen = 44;
  public produtoDescrHeight = 40;
  public produtoDescrFontSize = 0.9;
  public produtoIndispFontSize = 0.8;
  public produtoHeight = 200;
  public exibe4x4 = false;
  public exibe6x6 = false;
  public exibeGrade = false;
  public exibeGradeCliente = false;
  private lastCatalogLoadedPage: number = null;
  private lastSelectedProductCatalog: number = null;

  itensCatalogo = [];

  public form: FormGroup = new FormGroup({});
  public formOld = {};
  public mostraPreco = false;

  private currencyPipe: CurrencyPipe = new CurrencyPipe("pt-BR");

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public productService: ProductService,
    public pedidoService: PedidoService,
    public securityService: SecurityService,
    public toastService: ToastrService,
    private cartService: CartService
  ) {
    this.SITE = Configuracoes.SITE;
    this.itensCatalogo = JSON.parse(this.SITE.catalogo);
    this.pedido = this.pedidoService.pedido;
    this.tabelaPadrao = this.SITE.tabelaPadrao;
    this.API_IMAGENS = Configuracoes.API_IMAGENS;
    this.menuDinamico = Configuracoes.MISC.menuDinamico;


    Configuracoes.exibeVisualizacaoCatalogo.forEach(i => {
      if (i == 1)
        this.exibe4x4 = true;
      if (i == 2)
        this.exibe6x6 = true;
      if (i == 3)
        this.exibeGrade = true;
      if (i == 4)
        this.exibeGradeCliente = true;
    });

    if (!this.securityService.hasToken() && !this.SITE.mostraPrecoLoginAtivo)
      this.mostraPreco = true;
    if (
      this.securityService.hasToken() &&
      (!this.SITE.mostraPrecoPedidoSelecionado || this.pedido)
    )
      this.mostraPreco = true;

    if (window.localStorage.getItem("mostraComFotoModo")) {
      if (window.localStorage.getItem("mostraComFotoModo") == "true") {
        this.productService.mostraComFotoModo = true;
      } else {
        this.productService.mostraComFotoModo = false;
      }
    }
    if (window.localStorage.getItem("somenteDisponiveis")) {
      if (window.localStorage.getItem("somenteDisponiveis") == "true") {
        this.productService.somenteDisponiveis = true;
      } else {
        this.productService.somenteDisponiveis = false;
      }
    }

    let modoVisu = "";

    if (window.localStorage.getItem("visualizacaoModo")) {
      modoVisu = window.localStorage.getItem("visualizacaoModo");
    } else if (this.pedido !== undefined) {
      modoVisu = Configuracoes.modoVisualizacaoCatalogo;
    }

    if ((modoVisu == 'grade' || modoVisu == 'gradeClientes') && this.pedido && this.pedido.tipo.precoDivergente) {
      this.productService.visualizacaoModo = "4x4";
      window.localStorage.setItem("visualizacaoModo", "4x4");
      modoVisu = "4x4";
      Swal.fire(
        "Modo visualização",
        "Visualização SKU não suporta preço divergente",
        "info"
      );
    }

    // if (modoVisu) {
    switch (modoVisu) {
      case "6x6": {
        this.produtoColXL = 2;
        this.produtoDescrLen = 26;
        this.produtoDescrHeight = 40;
        this.produtoDescrFontSize = 0.8;
        this.produtoIndispFontSize = 0.7;
        this.produtoHeight = 160;
        this.productService.visualizacaoModo = "6x6";
        break;
      }
      case "8x8": {
        this.produtoColXL = 118;
        this.produtoDescrLen = 20;
        this.produtoDescrHeight = 32;
        this.produtoDescrFontSize = 0.7;
        this.produtoIndispFontSize = 0.55;
        this.produtoHeight = 110;
        this.productService.visualizacaoModo = "8x8";
        break;
      }
      case "grade": {
        this.productService.visualizacaoModo = "grade";

        if (this.pedido && this.pedido.clientes.length > 1) {
          this.productService.visualizacaoModo = "4x4";
          window.localStorage.setItem("visualizacaoModo", "4x4");
          Swal.fire(
            "Modo visualização",
            "Visualização SKU não suportada para mais de 01 cliente",
            "info"
          );
        }
        break;
      }

      case "gradeClientes": {
        this.productService.visualizacaoModo = "gradeClientes";
        this.productService.selectedOrder = 2;
        break;
      }

      default: {
        this.productService.visualizacaoModo = "4x4";
        window.localStorage.setItem("visualizacaoModo", "4x4");
      }
    }
    this.productService.refresh = this.ngOnInit.bind(this);
  }

  ngOnDestroy(): void {
    this.pedidoService.removeObserverToRemovedOrders(this);
  }


  notify(pedido: Order) {
    this.selectByPage(0, true);
  }

  onSomenteComFotos() {
    if (this.productService.mostraComFotoModo) {
      this.productService.mostraComFotoModo = false;
      window.localStorage.setItem("mostraComFotoModo", "false");
    } else {
      this.productService.mostraComFotoModo = true;
      window.localStorage.setItem("mostraComFotoModo", "true");
    }
    this.selectByPage(0, true);
  }

  onSomenteDisponiveis() {
    if (this.productService.somenteDisponiveis) {
      this.productService.somenteDisponiveis = false;
      window.localStorage.setItem("somenteDisponiveis", "false");
    } else {
      this.productService.somenteDisponiveis = true;
      window.localStorage.setItem("somenteDisponiveis", "true");
    }
    this.selectByPage(0, true);
  }

  onMostra4em4() {
    let atualiza = false;
    if (['grade', 'gradeClientes'].includes(this.productService.visualizacaoModo)) {
      atualiza = true;
    }
    this.produtoColXL = 3;
    this.produtoDescrLen = 44;
    this.produtoDescrHeight = 40;
    this.produtoDescrFontSize = 0.9;
    this.produtoIndispFontSize = 0.7;
    this.produtoHeight = 200;
    this.productService.visualizacaoModo = "4x4";
    window.localStorage.setItem("visualizacaoModo", "4x4");
    if (atualiza) {
      this.selectByPage(0, true);
    }
  }

  onMostra6em6() {
    let atualiza = false;
    if (['grade', 'gradeClientes'].includes(this.productService.visualizacaoModo)) {
      atualiza = true;
    }
    this.produtoColXL = 2;
    this.produtoDescrLen = 26;
    this.produtoDescrHeight = 40;
    this.produtoDescrFontSize = 0.8;
    this.produtoIndispFontSize = 0.7;
    this.produtoHeight = 160;
    this.productService.visualizacaoModo = "6x6";
    window.localStorage.setItem("visualizacaoModo", "6x6");
    if (atualiza) {
      this.selectByPage(0, true);
    }
  }

  onMostra8em8() {
    let atualiza = false;
    if (this.productService.visualizacaoModo == "grade") {
      atualiza = true;
    }
    this.produtoColXL = 118;
    this.produtoDescrLen = 20;
    this.produtoDescrHeight = 32;
    this.produtoDescrFontSize = 0.7;
    this.produtoIndispFontSize = 0.55;
    this.produtoHeight = 110;
    this.productService.visualizacaoModo = "8x8";
    window.localStorage.setItem("visualizacaoModo", "8x8");
    if (atualiza) {
      this.selectByPage(0, true);
    }
  }

  onMostraGrade() {
    if (this.pedido && this.pedido.clientes.length > 1) {
      Swal.fire(
        "Modo visualização",
        "Visualização SKU não suportada para mais de 01 cliente",
        "info"
      );
    } else if (this.pedido && this.pedido.tipo.precoDivergente) {
      Swal.fire(
        "Modo visualização",
        "Visualização SKU não suporta preço divergente",
        "info"
      );
    } else {
      this.productService.visualizacaoModo = "grade";
      window.localStorage.setItem("visualizacaoModo", "grade");
      this.selectByPage(0, true);
    }
  }

  onMostraGradeClientes() {
    if (this.pedido && this.pedido.tipo.precoDivergente) {
      Swal.fire("Modo visualização", "Visualização SKU não suporta preço divergente", 'info');
    } else {
      this.productService.visualizacaoModo = "gradeClientes";
      this.productService.selectedOrder = 2;
      window.localStorage.setItem("visualizacaoModo", "gradeClientes");
      this.selectByPage(0, true);
    }
  }

  ngOnInit() {
    this.selectByPage(0, true);
    this.pedidoService.registerObserverToRemovedOrders(this);
  }

  share() {
    if (this.productService.loading) return;

    this.productService.selectTabelaFilial();
    let tabela = null;
    let filial = null;
    if (this.productService.tabelaSelecionada) {
      tabela = this.productService.tabelaSelecionada;
    }
    if (this.productService.filialSelecionada) {
      filial = this.productService.filialSelecionada;
    }
    let share = {
      tabela: tabela,
      filial: filial,
      filtro: this.productService.filtro,
      mostraPreco: this.SITE.mostraPrecoLinkShare,
      mostraComFotoModo: this.productService.mostraComFotoModo,
      somenteDisponiveis: this.productService.somenteDisponiveis,
    };
    this.productService.share(
      share,
      function (resp) {
        this.shareLink =
          document.location.origin + "/s/" + resp.id + "/catalogo";
        $("#shareCatalogoLink").modal("show");
      }.bind(this)
    );
  }

  copyText() {
    let el = document.getElementById("shareCatalogoLinkInput") as any;
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  removeBusca() {
    this.productService.filtro = {};
    this.productService.filtroDescr = [];
    this.toScroll = false;
    this.products = [];
  }

  limpaBusca(busca) {
    this.productService.filtro["busca"] = this.productService.filtro[
      "busca"
    ].filter((b) => b != busca);
    if (this.productService.filtro["busca"].length == 0) {
      delete this.productService.filtro["busca"];
    }
    this.toScroll = false;
    this.products = [];
    this.selectByPage(0, true);
  }

  limpaBuscaDescr(id, filtro) {
    this.productService.filtroDescr = this.productService.filtroDescr.filter(
      (f) => f.id + f.filtro != id + filtro
    );
    this.productService.filtro[filtro] = this.productService.filtro[
      filtro
    ].filter((f) => f != id);
    if (this.productService.filtro[filtro].length == 0) {
      delete this.productService.filtro[filtro];
    }
    this.toScroll = false;
    this.products = [];
    this.selectByPage(0, true);
  }

  filtrarPorMenuDinamico(ids) {
    this.productService.filtro['menuIds'] = ids;
    this.selectByPage(0, true);
    $(window).scrollTop(0);
  }

  filtrar(filtro, filtroDescr, id, event) {
    if (this.productService.loading) return;

    let isChecked = event.target.checked;
    if (this.productService.filtro[filtro]) {
      const index = this.productService.filtro[filtro].indexOf(id);
      if (index == -1) {
        this.productService.filtro[filtro].push(id);
      } else {
        this.productService.filtro[filtro].splice(index, 1);
      }
      if (this.productService.filtro[filtro].length == 0) {
        delete this.productService.filtro[filtro];
      }
    } else {
      this.productService.filtro[filtro] = [id];
    }

    this.productService.filtroDescr = this.productService.filtroDescr.filter(
      (f) => f.id + f.filtro != id + filtro
    );
    if (isChecked)
      this.productService.filtroDescr.push({
        id: id,
        filtro: filtro,
        label: filtroDescr,
      });

    this.selectByPage(0, true);
    $(window).scrollTop(0);
  }

  selectByPage(page, force = false) {
    if (!force && page >= this.totalPages) {
      this.toScroll = false;
      //console.log('selectByPage saindo...', 'page: ', page, 'totalPages: ', this.totalPages);
      return;
    }
    this.page = page;

    if (page == 0) {
      this.products = [];
    }
    //console.log('selectByPage', this.page);
    //console.log('filtro', this.filtro);
    this.productService.catalogo(
      page,
      this.pageSize,
      this.callbackProducts.bind(this)
    );
    //this.productService.products(page, this.pageSize, this.filtro, this.ordem, this.direcao, this.callbackProducts.bind(this));
  }

  calculaImposto(imposto, _precopromocional, _preco) {
    if (imposto)
      return (
        "+ R$ " +
        this.currencyPipe.transform(
          this.calculaValorImposto(imposto, _precopromocional, _preco),
          "",
          ""
        )
      );
  }

  calculaValorImposto(imposto, _precopromocional, _preco) {
    if (imposto) {
      let preco: number = 0;
      if (_precopromocional > 0) {
        preco = _precopromocional;
      } else {
        preco = _preco;
      }
      let valorImposto: number = this.productService.calculaValorImposto(
        preco,
        imposto.ipi,
        imposto.alicms,
        imposto.substicms,
        imposto.alicmsorigem,
        imposto.fcpSt
      );
      return valorImposto;
    }
    return 0;
  }

  addItemCart(e) {
    let id = e.target.name;
    let _id = id.split("@");
    let pro: number = parseInt(_id[0]);
    let cor: number = parseInt(_id[1]);
    let est: number = parseInt(_id[2]);
    let tam: string = _id[3];
    let cli: number = parseInt(_id[4]);
    let value = this.form.get(id).value;
    let qtd: number = value !== null && value != '' ? parseInt(value) : 0;
    let preco: number = 0;
    let imposto;
    let proRef = null;
    let proDescr = null;
    $('input[name="' + id + '"]').blur();

    if (
      ((!qtd || qtd < 1) && this.formOld[id] == 0) ||
      this.formOld[id] == qtd
    ) {
      return;
    }
    for (let i = 0; i < this.products.length; i++) {
      let p = this.products[i];

      console.log(
        "produto",
        p["id"],
        pro,
        "cor",
        p["corid"],
        cor,
        "estampa",
        p["estampaid"],
        est,
        "tamanho",
        p["tamanho"],
        tam
      );

      if (
        p["id"] == pro &&
        p["corid"] == cor &&
        p["estampaid"] == est &&
        p["tamanho"] == tam
      ) {
        if (!this.form.valid) {
          if (p.estoque < p.quantidademaximadisponivelgrade) {
            this.toastService.warning("Estoque insuficiente!", "Produto", {
              positionClass: "toast-bottom-left",
            });
          } else {
            this.toastService.warning(
              "Máximo disponível " + p["quantidademaximadisponivelgrade"],
              "Produto",
              { positionClass: "toast-bottom-left" }
            );
          }
          $('input[name="' + id + '"]').focus();
          return;
        }

        if (qtd % p["multiplo"] !== 0) {
          this.toastService.warning(
            "Quantidade deve ser múltiplo de " + p["multiplo"],
            "Produto",
            { positionClass: "toast-bottom-left" }
          );
          $('input[name="' + id + '"]').focus();
          return;
        }

        imposto = p["imposto"];
        proRef = p["referencia"];
        proDescr = p["descricao"];
        if (p["precopromocional"] > 0) {
          preco = p["precopromocional"];
        } else {
          preco = p["preco"];
        }

        break;
      }
    }

    if (preco <= 0) {
      Swal.fire("Produto", "Preço incorreto", "warning");
      return;
    }

    let valorImposto: number = 0;
    let impostoCalc = {
      al_fcp_st: null,
      valor_fcp_st: null,
      base_ipi: null,
      al_ipi: null,
      base_sub: null,
      al_sub: null,
      valor_ipi: null,
      valor_sub: null,
      base_fcp_st: null,
    };
    if (imposto) {
      impostoCalc = this.productService.calculaImposto(
        preco,
        imposto.ipi,
        imposto.alicms,
        imposto.substicms,
        imposto.alicmsorigem,
        imposto.fcpSt
      );
      valorImposto = this.productService.calculaValorImposto(
        preco,
        imposto.ipi,
        imposto.alicms,
        imposto.substicms,
        imposto.alicmsorigem,
        imposto.fcpSt
      );
    }
    let ___total = preco + valorImposto;
    let newItem: OrderItem = {
      cor: cor,
      corDescricao: "",
      estampa: est,
      tamanho: tam,
      cliente: cli,
      produto: pro,
      produtoReferencia: proRef,
      produtoDescricao: proDescr,
      quantidade: qtd,
      preco: preco,
      acrescimo: 0,
      acrescimoPercent: 0,
      desconto: 0,
      descontoPercent: 0,
      total: qtd * ___total,
      imp_estado: imposto ? imposto.estado : null,
      imp_ipi: imposto ? imposto.ipi : null,
      imp_alicms: imposto ? imposto.alicms : null,
      imp_alicmsorigem: imposto ? imposto.alicmsorigem : null,
      imp_alfcpst: imposto ? imposto.fcpSt : null,
      imp_substicms: imposto ? imposto.substicms : null,
      imp_baseipi: impostoCalc.base_ipi,
      imp_basesubst: impostoCalc.base_sub,
      imp_basefcpst: impostoCalc.base_fcp_st,
      imp_valoripi: impostoCalc.valor_ipi,
      imp_valorsubst: impostoCalc.valor_sub,
      imp_valorfcpst: impostoCalc.valor_fcp_st,
      imp_valorimposto: valorImposto > 0 ? valorImposto : null,
      codigoBarras: null,
    };
    //console.log(newItem);
    this.pedidoService.setCartItemsSku(
      [newItem],
      function (resp) {
        this.formOld[id] = newItem.quantidade;
        this.form.markAsPristine();
        this.pedido = resp;
        this.pedidoService.select(resp);
      }.bind(this),
      function (error) {
        let obj = JSON.parse(error._body);
        if (obj && obj.msg) {
          this.error = true;
          this.errorMsg = obj.msg;
        }
      }.bind(this)
    );
  }

  callbackProducts(resp) {
    console.log(resp);
    this.filtros = resp["filtros"];
    let pages = resp["produtos"];
    this.toScroll = true;
    this.totalPages = pages.totalPages;
    this.totalElements = pages.totalElements;
    if (pages.content) {
      console.log("pages", pages);
      if (
        this.pedido &&
        this.productService.visualizacaoModo.startsWith("grade")
      ) {
        // let cli = (this.pedido ? this.pedido.clientes[0].cliente.id + '' : '');

        for (var i = 0; i < pages.content.length; i++) {
          let product = pages.content[i];
          this.products.push(product);

          for (var c = 0; c < this.pedido.clientes.length; c++) {
            let cli = this.pedido.clientes[c].cliente.id;
            let value = "";

            if (this.productService.visualizacaoModo == "gradeClientes") {
              let clienteProduto =
                pages.clientes != null &&
                pages.clientes.filter(
                  (c_) => c_.cliente === cli && c_.produto === product.id
                );

              if (clienteProduto.length > 0)
                value = clienteProduto[0].quantidade;
            } else {
              if (product.quantidade) {
                value = product.quantidade + "";
              }
            }

            let id =
              product.id +
              "@" +
              product.corid +
              "@" +
              product.estampaid +
              "@" +
              product.tamanho +
              "@" +
              cli;

            let fc = new FormControl(value, [Validators.min(0)]);
            if (this.pedido.tipo.validaEstoque) {
              fc = new FormControl(value, [
                Validators.min(0),
                Validators.max(
                  product.estoque < product.quantidademaximadisponivelgrade
                    ? product.estoque
                    : product.quantidademaximadisponivelgrade
                ),
              ]);
            }
            this.form.addControl(id, fc);
            this.formOld[id] = product.quantidade ? product.quantidade : 0;
          }
        }
      } else {
        for (var i = 0; i < pages.content.length; i++) {
          let p = pages.content[i];
          this.products.push(p);
        }
      }
    }
    if (
      this.totalPages == 0 &&
      this.totalElements == 1 &&
      this.products.length == 1
    ) {
      this.router.navigate(["/produto/" + this.products[0].id]);
      this.removeBusca();
    }

    if (
      this.pedido &&
      this.productService.visualizacaoModo == "gradeClientes"
    ) {
      let clientes: any = [];
      this.pedidoService.pedido.clientes.forEach((pc) => {
        clientes.push(pc.cliente);
      });
      this.clientes = clientes;
    }
  }

  callPhotos(idProduto, codCor) {
    this.productService.getPhotos(
      idProduto,
      function (resp) {
        //console.log(resp);
        let temVariacao = false;
        let _htmlImagens = "";
        for (let i = 0; i < resp.length; i++) {
          let img = resp[i];
          _htmlImagens +=
            '<a href="' +
            Configuracoes.API_IMAGENS +
            "produtos/" +
            img.nome +
            '" data-gallery="gallery-catalogo"></a>';
        }
        $("#htmlLightBox").html(_htmlImagens);
        $("#htmlLightBox a:first").ekkoLightbox();
        //console.log(_htmlImagens);
      }.bind(this)
    );
  }

  onSortChange(event) {
    let value = event.target.value;
    if (!value) return;

    switch (parseInt(value)) {
      case 1:
        this.productService.ordem = "data";
        break;

      case 2:
      case 3:
        this.productService.ordem = "descricao";
        break;

      case 4:
      case 5:
        this.productService.ordem = "preco";
        break;

      default: {
        break;
      }
    }

    switch (parseInt(value)) {
      case 2:
      case 5:
        this.productService.direcao = "ASC";
        break;

      default:
        this.productService.direcao = "DESC";
        break;
    }

    this.selectByPage(0, true);
  }

  @HostListener("scroll", ["$event"])
  onTableScroll() {
    console.log("tchau", "onWindowScroll");
    let elementTop = $("#fim-produtos").offset().top;
    let elementBottom = elementTop + $("#fim-produtos").outerHeight();
    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();
    if (elementBottom > viewportTop && elementTop < viewportBottom) {
      if (this.toScroll) {
        this.toScroll = false;
        console.log("tchau", "onWindowScroll");
        this.selectByPage(this.page + 1);
      }
    }
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    let elementTop = $("#fim-produtos").offset().top;
    let elementBottom = elementTop + $("#fim-produtos").outerHeight();
    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();
    if (elementBottom > viewportTop && elementTop < viewportBottom) {
      if (this.toScroll) {
        this.toScroll = false;
        console.log("onWindowScroll");
        this.selectByPage(this.page + 1);
      }
    }
  }
}
