import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Configuracoes } from './entities/configuracoes';
import { NavigationEnd, Router } from '@angular/router';
import { SecurityService } from './services/security-service';
import { PedidoService } from './services/pedido-service';
declare var $: any;
declare var gtag: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    public metaService: Meta,
    public titleService: Title,
    public securityService: SecurityService,
    public pedidoService: PedidoService) {
    if (Configuracoes.gtagId) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag("config", Configuracoes.gtagId, {
            page_path: event.urlAfterRedirects,
          });
        }
      });
    }
  }

  ngOnInit() {
    //console.log('app.component - ngOnInit');
    //this.productService.products();

    //Definindo o título e metadados
    this.titleService.setTitle(Configuracoes.SITE.title);
    this.metaService.updateTag({
      name: "description",
      content: Configuracoes.SITE.metatagDescription,
    });
    this.metaService.updateTag({
      name: "Abstract",
      content: Configuracoes.SITE.metatagAbstract,
    });
    this.metaService.updateTag({
      name: "author",
      content: "Ágil B2B www.agilb2b.com.br",
    });
    this.metaService.updateTag({
      name: "copyright",
      content: "Ágil B2B © 2020",
    });

    //Definindo o tema
    this.configura();
  }

  configura() {
    let dominio = Configuracoes.NAME;
    let API_BANNERS = Configuracoes.API_BANNERS;
    let IMAGEBACKGROUND = Configuracoes.IMAGEBACKGROUND;
    let IMAGELOGO = Configuracoes.IMAGELOGO;
    let API_IMAGENS = Configuracoes.API_IMAGENS;
    let selectedTheme = Configuracoes.SITE.theme;
    let theme = Configuracoes.themes.filter((i) => i.id == selectedTheme)[0];
    let themeColor = theme.color;
    let font = Configuracoes.SITE.font; //Roboto, Lato, Oswald, Raleway
    let fontSize = Configuracoes.SITE.fontSize; //10 - 20
    let borderRadius = Configuracoes.SITE.borderRadius;
    let produtoDescr2Height = 185;
    let produtoStyle = JSON.parse(Configuracoes.SITE.produtoStyle);
    if (produtoStyle) {
      if (produtoStyle["descr2Height"] !== undefined) {
        produtoDescr2Height = produtoStyle["descr2Height"];
      }
    }
    let css = `
      * {
        font-family: '${font}', sans-serif;
        font-size: ${fontSize}px;
      }
      .gcc-menu-horizontal > ul > li:hover > a:before,
      .gcc-menu-horizontal > ul > li:hover > span:before {
        background-color: ${themeColor} !important;
      }
      a:not(.btn):hover {
        color: ${themeColor} !important;
      }
      a.btn,
      button.btn, input, select, span.badge, .badge.badge-info button,
      .form-group .form-control,
      .input-group .form-control,
      .imgslider div img,
      #listaImagens div img,
      .product img,
      .gcc-produtos .produto img,
      .gcc-menu-horizontal > ul > li > ul > li a,
      .gcc-menu-horizontal > ul > li > ul > li span,
      #login-gcc, .btn.btn-primary {
        border-radius: ${borderRadius}px;
      }
      .form-group .input-group-text,
      .input-group .input-group-text {
        border-top-right-radius: ${borderRadius}px;
        border-bottom-right-radius: ${borderRadius}px;
      }
      .input-group-prepend .input-group-text {
        border-top-left-radius: ${borderRadius}px !important;
        border-bottom-left-radius: ${borderRadius}px !important;
      }
      input, button, select, optgroup, textarea, .input-group-text, .input-group-prepend {
        font-size: inherit !important;
      }
      .sortable-item {
        padding: 4px 8px;
        margin-bottom: 4px;
        line-height: 1.4em;
        text-transform: capitalize;
        cursor: grab;
        border: 1px solid transparent;
        border-radius: ${borderRadius}px;
        border-color: ${themeColor};
      }
      .sortable-item-right {
        display: inline-block;
      }
      .obsproduto2 .collapse, .obsproduto2 .collapsing {
        height: ${produtoDescr2Height}px;
      }
      .ekko-lightbox-nav-overlay a span {
        color: ${themeColor};
      }
    `;
    this.metaService.updateTag({ name: "theme-color", content: themeColor });
    $(function () {
      $("head").append(
        '<link rel="stylesheet" type="text/css" href="assets/css/theme-' +
        theme.id +
        '.css">'
      );
      $("head").append(
        '<link rel="icon" type="image/x-icon" href="' +
        API_IMAGENS +
        'favicon.ico">'
      );
      $("style[id='gcc-styles']").append(css);
      setTimeout(function () {
        $("#gcc").css("opacity", "1");
      }, 200);
    });
  }

  onActivate(event) {
    $(window).scrollTop(0);
    $(".modal").modal("hide");
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    return !this.pedidoService.existePedidoEmAberto();
  }
}
