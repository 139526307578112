import { OrderItem } from "src/app/entities/orderItem";

export function getOrderItemFromProduct(product: any, taxCalc: any, tax: any, taxValue: number, clientId: number, quantity: number, total: number): OrderItem {
    let item: OrderItem = {
        cor: product.cor,
        corDescricao: product.corDescricao,
        estampa: product.estampa,
        tamanho: product.tamanho,
        cliente: clientId,
        produto: product.id,
        produtoReferencia: product.referencia,
        produtoDescricao: product.descricao,
        quantidade: quantity,
        preco: product.precopromocional ? product.precopromocional : product.preco,
        acrescimo: 0,
        acrescimoPercent: 0,
        desconto: 0,
        descontoPercent: 0,
        total: total,
        imp_estado: tax ? tax.estado : null,
        imp_ipi: tax ? tax.ipi : null,
        imp_alicms: tax ? tax.alicms : null,
        imp_alicmsorigem: tax ? tax.alicmsorigem : null,
        imp_alfcpst: tax ? tax.fcpSt : null,
        imp_substicms: tax ? tax.substicms : null,
        imp_baseipi: taxCalc ? null : taxCalc.base_ipi,
        imp_basesubst: taxCalc ? null : taxCalc.base_sub,
        imp_basefcpst: taxCalc ? null : taxCalc.base_fcp_st,
        imp_valoripi: taxCalc ? null : taxCalc.valor_ipi,
        imp_valorsubst: taxCalc ? null : taxCalc.valor_sub,
        imp_valorfcpst: taxCalc ? null : taxCalc.valor_fcp_st,
        imp_valorimposto: taxValue,
        codigoBarras: null,
    };

    return item;
}