import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-slider-imagem",
  templateUrl: "./slider-imagem.component.html",
  styleUrls: ["./slider-imagem.component.css"],
})
export class SliderImagemComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
